/**
 * PS_CTA_1.0
 * Import neccassary files
 * PS_CTA_1.1
 */
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';

/**
 * PS_CTA_1.2
 * @param agreementDetails
 * @param type
 * @returns Total amount
 * This function calculates the total amount against every agreement
 * PS_CTA_1.21
 */
export function calculateTotalDueAmount(
  agreementDetails: AgreementDetails,
  type?: string
) {
  let totalAmountDue = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
  let receiptAmount = agreementDetails?.totalDueAmount;
  const suspenseDiff = (
    Number(agreementDetails.suspenseAmount) - Number(agreementDetails.epoAmount)
  ).toFixed(2);

  if (agreementDetails.isReturnAgreement) {
    totalAmountDue = `(${(
      Number(agreementDetails?.suspenseDetails?.suspUse) -
      Number(agreementDetails?.suspenseDetails.suspAdd)
    ).toFixed(2)})`;
    receiptAmount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
  } else if (
    agreementDetails?.isOverSus &&
    Number(suspenseDiff) ==
      Number(agreementDetails.suspenseDetails.transferSusUse)
  ) {
    totalAmountDue = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
  } else if (agreementDetails?.isEPOSusAmtDue || agreementDetails?.isExactSus) {
    const totalAmount = (
      Number(agreementDetails.epoAmount) -
      (Number(agreementDetails.suspenseAmount) +
        Number(agreementDetails.suspenseDetails.transferSusAdd) -
        Number(agreementDetails.suspenseDetails.transferSusUse))
    ).toFixed(2);
    totalAmountDue =
      Number(totalAmount) < 0
        ? `(${Math.abs(Number(totalAmount)).toFixed(2)})`
        : totalAmount;
  } else if (
    Number(agreementDetails?.suspenseDetails?.suspUse) >
    Number(agreementDetails?.totalDueAmount)
  ) {
    const totalAmount = (
      Number(agreementDetails?.suspenseDetails?.suspUse) -
      Number(agreementDetails?.totalDueAmount)
    ).toFixed(2);
    totalAmountDue = `(${totalAmount})`;
    receiptAmount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
  } else {
    totalAmountDue = (
      Number(agreementDetails?.totalDueAmount) +
      (isNaN(Number(agreementDetails?.suspenseDetails?.suspAdd)) ||
      type == CONSTANTS.IS_CO_INITIAL
        ? 0
        : Number(agreementDetails?.suspenseDetails?.suspAdd)) -
      (isNaN(Number(agreementDetails?.suspenseDetails?.suspUse))
        ? 0
        : Number(agreementDetails?.suspenseDetails?.suspUse))
    ).toFixed(2);
  }
  return type == CONSTANTS.RECEIPT ? receiptAmount : totalAmountDue;
}
