import CONSTANTS from '../constants/constant';
import {
  commitmentPriorityArray,
  AdjustedInfo,
  CommitmentAgreementDetailsArray,
  TASarray,
  FreeTimeObj,
  AEFObj,
} from '../interface/paymentInfoInterface';

export function commitmentPriorityFormation(
  commitmentArray: CommitmentAgreementDetailsArray[]
) {
  const agreementIDArray: (string | number)[] = [];
  const commitmentPriorityArray: commitmentPriorityArray[] = [];
  commitmentArray.forEach((el: CommitmentAgreementDetailsArray) => {
    const obj: AdjustedInfo = {
      type: el.commitmentTypeRefCode,
      priority: getPriority(el.commitmentTypeRefCode, el.contractTypeId),
    };
    if (el.commitmentTypeRefCode == CONSTANTS.FTT) {
      const freetimeObj = freeTimePriority(
        commitmentArray,
        el.commitmentTypeValue as string
      );
      obj.value = freetimeObj;
    } else if (el.commitmentTypeRefCode == CONSTANTS.AEF) {
      const aefObj = epoPriority(commitmentArray);
      obj.value = aefObj;
    } else if (el.commitmentTypeRefCode == CONSTANTS.TUS) {
      const tasArray = transferUseSuspensePriority(commitmentArray);
      obj.value = [
        ...tasArray,
        {
          agreementID: el.agreementId,
          transferType: el.commitmentTypeRefCode,
          value: el.commitmentTypeValue as string,
        },
      ];
      //FTT , AEF, TUS,
    } else if (
      CONSTANTS.COMMITMENT_TYPE_ARRAY.includes(
        el.commitmentTypeRefCode as string
      ) ||
      (el.contractTypeId == CONSTANTS.TWO_STRING && el.commitmentTypeValue)
    ) {
      obj.value = el.commitmentTypeValue as string;
    } else {
      obj.value = Number(el.amount).toFixed(2);
    }

    const freeTimeCommitment = commitmentArray.filter(
      (obj) =>
        obj.commitmentTypeRefCode == CONSTANTS.FTT &&
        obj.agreementId == el.agreementId
    );

    updateCommitmentPriorityArray(
      agreementIDArray,
      el,
      commitmentPriorityArray,
      freeTimeCommitment,
      obj
    );
  });
  commitmentPriorityArray.map((el) => {
    el.adjustedInfo.sort(
      (a: AdjustedInfo, b: AdjustedInfo) => a?.priority - b.priority
    );
  });
  return commitmentPriorityArray;
}

function freeTimePriority(
  commitmentArray: CommitmentAgreementDetailsArray[],
  type: string
) {
  const dueDateCommitment = commitmentArray.find(
    (el) => el.commitmentTypeRefCode == CONSTANTS.DDA
  );
  const waivedServiceCommitment = commitmentArray.find(
    (el) => el.commitmentTypeRefCode == CONSTANTS.WOS
  );
  const waivedValue =
    waivedServiceCommitment?.commitmentTypeValue?.toLowerCase();
  const freetimeObj: FreeTimeObj = {
    nextDueDate:
      dueDateCommitment?.commitmentTypeValue ?? CONSTANTS.EMPTY_STRING,
    freeTimeType: type,
    waiveOptService: waivedValue == 'true' ? true : false,
  };
  return freetimeObj;
}

function epoPriority(commitmentArray: CommitmentAgreementDetailsArray[]) {
  const AEPDACommitment = commitmentArray.find(
    (el) => el.commitmentTypeRefCode == CONSTANTS.AEPDA
  );
  const AEACommitment = commitmentArray.find(
    (el) => el.commitmentTypeRefCode == CONSTANTS.AEA
  );
  const AEFObj: AEFObj = {
    pastDueRent: AEPDACommitment?.commitmentTypeValue ?? CONSTANTS.EMPTY_STRING,
    epoAmount: AEACommitment?.commitmentTypeValue ?? CONSTANTS.EMPTY_STRING,
  };
  return AEFObj;
}

function transferUseSuspensePriority(
  commitmentArray: CommitmentAgreementDetailsArray[]
) {
  const TASCommitment = commitmentArray.filter(
    (el) => el.commitmentTypeRefCode == CONSTANTS.TAS
  );
  const TASarray: TASarray[] = [];
  TASCommitment.forEach((item) => {
    TASarray.push({
      agreementID: item.agreementId,
      transferType: item.commitmentTypeRefCode as string,
      value: item.commitmentTypeValue as string,
    });
  });
  return TASarray;
}

function updateCommitmentPriorityArray(
  agreementIDArray: (string | number)[],
  el: CommitmentAgreementDetailsArray,
  commitmentPriorityArray: commitmentPriorityArray[],
  freeTimeCommitment: CommitmentAgreementDetailsArray[],
  obj: AdjustedInfo
) {
  if (agreementIDArray.includes(el.agreementId)) {
    const index = commitmentPriorityArray.findIndex(
      (obj) => obj.agreementID == el.agreementId
    );
    if (
      !(
        freeTimeCommitment.length > 0 &&
        el.commitmentTypeRefCode == CONSTANTS.DDA
      ) &&
      obj.value
    )
      commitmentPriorityArray[index].adjustedInfo.push(obj);
  } else {
    if (
      !(
        freeTimeCommitment.length > 0 &&
        el.commitmentTypeRefCode == CONSTANTS.DDA
      ) &&
      obj.value
    ) {
      agreementIDArray.push(el.agreementId);
      commitmentPriorityArray.push({
        agreementID: el.agreementId,
        adjustedInfo: [obj],
      });
    }
  }
}

function getPriority(refCode: string | null, type: string): number {
  if (type == CONSTANTS.TWO_STRING) {
    if (refCode == CONSTANTS.ES) return 1;
    else if (refCode == CONSTANTS.DDA) return 2;
    else if (refCode == CONSTANTS.DC) return 3;
  } else if (CONSTANTS.LFC_LFW_TOS_AOS_ARRAY.includes(refCode as string))
    return 1;
  else if (refCode == CONSTANTS.ES) return 2;
  else if (refCode == CONSTANTS.AEF) return 3;
  else if (CONSTANTS.FTT_DDA_ARRAY.includes(refCode as string)) return 4;
  else if (CONSTANTS.AS_US_TAS_TUS_ARRAY.includes(refCode as string)) return 5;

  return 6;
}
