/* eslint-disable  */
// import React, { useState } from "react";
import { useEffect } from "react";
// import { globalStyles } from "./componentstyles/globalstyles";
import { PayHistory } from "../../stylesJS/PayHistory";
import { CircularProgress } from "@mui/material";
import { ReactComponent as Successimg } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alertimg } from '../../assets/images/no-records-found.svg';

import {
  RACTableCell,
  RACTable,
  RACTableRow,
  Grid,
  RACButton,
  Typography,
  RACModalCard,
  Link,
} from "@rentacenter/racstrap";
import React, { useRef, useState } from "react";
enum CONTENT_TYPE {
  pdf = 'application/pdf',
  html = 'text/html',
  jpg = 'image/jpg',
  jpeg = 'image/jpeg',
  jpe = 'image/jpe',
  gif = 'image/gif',
  png = 'image/png',
  bmp = 'image/bmp',
}
import InfiniteScroll from "react-infinite-scroll-component";

import { getDocument } from "../../api/user";
import { takePaymentStyles } from "../../stylesJS/takePaymentStyles";
import { epoScheduleStyles } from "../../stylesJS/epoScheduleStyles";
import { printHTML } from "./print";
import { ClubHistoryPrintInfo } from "./ClubHistoryHTML";
import CustomerSignature from '../../racstrapComponents/microfrontends/CustomerSignature';
import CONSTANTS from "../constants/constant";
import { AgreementDetails } from "../interface/paymentInfoInterface";
import { ClubActivityInfo, ClubPaymentHistoryGridInfo, customerDetails } from "../interface/paymentHistoryInterface";
import { GetDocumentResponse } from "../interface/acceptPaymentInterface";
type ByteArray = Uint8Array;

// import { Link } from 'react-router-dom';

export default function ClubPayment(props) {
  const classes = takePaymentStyles();
  const epoScheduleClassName = epoScheduleStyles();
   const [clubDocumentSignStatus,setclubDocumentSignStatus]=useState<boolean>(true)

  useEffect(()=>{
    const clubinfo =props.paygridvalues.filter(
      (x:AgreementDetails) => x.agreementType == CONSTANTS.CLUB
    );
    if(clubinfo.length>CONSTANTS.ZERO_NUMBER){
      if((clubinfo[0].clubDocumentSignStatus==null||clubinfo[0].clubDocumentSignStatus=='UK')&&clubinfo[0].originAgreementId==null){
        setclubDocumentSignStatus(false)
      }

    }

  },[])
  //receipt states

  // const eventFrame = useRef<HTMLIFrameElement>(null);

  const eventFrame1 = useRef<HTMLIFrameElement>(null);
  const eventFrame2 = useRef<HTMLIFrameElement>(null);
  const eventFrame3 = useRef<HTMLIFrameElement>(null);



  let [viewReceiptPopup, setviewReceiptPopup] = useState<boolean>(false); // for receipt popuphide and show
  let [viewCooPopup, setviewCooPopup] = useState<boolean>(false); // for receipt popuphide and show
  let [viewExtPopup, setviewExtPopup] = useState<boolean>(false); // for receipt popuphide and show


  const [enablesuccesspopup, setenablesuccesspopup] = useState<boolean>(false);
  const [enableServiceFailpopup, setenableServiceFailpopup] = useState<boolean>(false);


  const [masterloader, setmasterloader] = useState<boolean>(false);
  const [masterloader1, setmasterloader1] = useState<boolean>(false);
  const [customerSignFlow,setcustomerSignFlow]=useState<boolean>(false)

  const [FileInBase64, setFileInBase64] = useState<string>(); //for setting the receipt url
  const [FileInBase64coo, setFileInBase64coo] = useState<string>();
  const [FileInBase64Ext, setFileInBase64Ext] = useState<string>();

  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];

  const Success = () => {
    console.log("entered success")
    return (
      <div>

        {masterloader1 ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            className={`${classes.textCenter} ${classes.justifyCenter}`}
          >
            <Successimg></Successimg>
          </Grid>
          <Grid item md={12} className={classes.textCenter}>
            <Typography
              className={`${epoScheduleClassName.popupText} ${classes.mt3}`}
            >
              Email has been sent successfully{CONSTANTS.STRING_WITH_SINGLE_SPACE}
            </Typography>
          </Grid>
        </Grid>
        <div className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => setenablesuccesspopup(false)}
          >
            OK
          </RACButton>
        </div>
      </div>
    );
  };


  const Service = () => {
    console.log("entered success")
    return (
      <div>

        {masterloader1 ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            className={`${classes.textCenter} ${classes.justifyCenter}`}
          >
            <Alertimg></Alertimg>
          </Grid>
          <Grid item md={12} className={classes.textCenter}>
            <Typography
              className={`${epoScheduleClassName.popupText} ${classes.mt3}`}
            >
              Not able to get receipt information{CONSTANTS.STRING_WITH_SINGLE_SPACE}
            </Typography>
          </Grid>
        </Grid>
        <div className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => setenableServiceFailpopup(false)}
          >
            OK
          </RACButton>
        </div>
      </div>
    );
  };
  const print = (type: string) => {
    console.log(type)

    if (type == "rec") {
      eventFrame1.current?.contentWindow?.print();
    } else if (type == "coo") {
      eventFrame2.current?.contentWindow?.print();
    } else if (type == "ext") {
      eventFrame3.current?.contentWindow?.print();
    }
  };

  const b64toBlob = (
    b64Data: string,
    contentType = 'application/pdf',
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: ByteArray[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };



  const receiptPopUp = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid item md={12} className={`${classes.textcenter} ${classes.paymentmodal}`}>
          <iframe
            ref={eventFrame1}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${FileInBase64}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton variant="outlined" color="secondary" className={classes.me2} onClick={() => setviewReceiptPopup(false)}>
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => print("rec")}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };



  const EXTPopUp = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid item md={12} className={`${classes.textcenter} ${classes.paymentmodal}`}>
          <iframe
            ref={eventFrame3}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${FileInBase64Ext}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton variant="outlined" color="secondary" className={classes.me2} onClick={() => setviewExtPopup(false)}>
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => print("ext")}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const cooPopUp = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid item md={12} className={`${classes.textcenter} ${classes.paymentmodal}`}>
          <iframe
            ref={eventFrame2}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${FileInBase64coo}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton variant="outlined" color="secondary" className={classes.me2} onClick={() => setviewCooPopup(false)}>
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => print("coo")}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const getContentType = (fileName: string) => {
    // if (fileContentType) return fileContentType;

    let result;
    if (fileName && typeof fileName === 'string') {
      const splits = fileName.split('.');
      const extension = splits[splits.length - 1] as string;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      result = CONTENT_TYPE[extension];
    }
    return result || CONTENT_TYPE.pdf;
  };

  const ReceiptPopup = async (ReceiptId: string) => {



    setmasterloader1(true);
    console.log("entered receipt popup")
    console.log(ReceiptId)
    let ReceiptIdstr = ReceiptId.toString()

    let receiptResponse:GetDocumentResponse = await getDocument(ReceiptIdstr, "RCP");

    console.log(receiptResponse, "receipt response")



    if (receiptResponse.status == 500 || Object.keys(receiptResponse.data).length == CONSTANTS.ZERO_NUMBER) {
      setenableServiceFailpopup(true)
      setmasterloader1(false);

    } else {

      let Coorecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === CONSTANTS.RECEIPT_TYPE_COOS
      );

      let Receiptrecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === CONSTANTS.RECEIPT_TYPE_PRCT
      );
      let Extrecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === CONSTANTS.RECEIPT_TYPE_EXTRF
      );

      console.log(Coorecord, "coo redord")
      console.log(Receiptrecord, "coo redord")



      if (Receiptrecord.length != CONSTANTS.ZERO_NUMBER ? Receiptrecord[0]?.fileObject == null : null) {
        setenableServiceFailpopup(true)
        setmasterloader1(false);

      }

      if (Coorecord.length != CONSTANTS.ZERO_NUMBER ? Coorecord[0]?.fileObject == null : null) {
        setenableServiceFailpopup(true)
        setmasterloader1(false);

      }

      if (Extrecord.length != CONSTANTS.ZERO_NUMBER ? Extrecord[0]?.fileObject == null : null) {
        setenableServiceFailpopup(true)
        setmasterloader1(false);

      }

      if (receiptResponse.data.fileList.length != CONSTANTS.ZERO_NUMBER) {
        console.log("entered if condition")


        if (Receiptrecord.length != CONSTANTS.ZERO_NUMBER) {

          const type = getContentType(Receiptrecord[0]?.fileName)
          setFileInBase64(
            URL.createObjectURL(
              b64toBlob(Receiptrecord[0]?.fileObject, type)
            )
          );


          if(Receiptrecord[0]?.fileObject == null){
            setenableServiceFailpopup(true)
          }else{
            setviewReceiptPopup(true)
          }



        }





        if (Coorecord.length != CONSTANTS.ZERO_NUMBER) {
          const type = getContentType(Coorecord[0]?.fileName)

          setFileInBase64coo(
            URL.createObjectURL(
              b64toBlob(Coorecord[0]?.fileObject, type)
            )
          );

          if(Coorecord[0]?.fileObject == null){
            setenableServiceFailpopup(true)
          }else{
            setviewCooPopup(true)
          }

          // setviewCooPopup(true)
        }




        if (Extrecord.length != CONSTANTS.ZERO_NUMBER) {
          const type = getContentType(Extrecord[0]?.fileName)


          setFileInBase64Ext(
            URL.createObjectURL(
              b64toBlob(Extrecord[0]?.fileObject, type)
            )
          );

          if(Extrecord[0]?.fileObject == null){
            setenableServiceFailpopup(true)
          }else{
            setviewExtPopup(true)
          }
        }

      }



      console.log("base 64 set receipt")
      setmasterloader1(false);
      // setviewReceiptPopup(true)
      // setviewCooPopup(true)
      console.log("true set")

    }



  }



  // console.log(props.ClubGridInfoResp,"club grid club")

  // console.log(props.clubActivityInfo,"club activityinfo club")

  // console.log(props.name,"nametest")
  // console.log(props.commoninformation,"common info")

  // console.log(props.clubinfo,"clubinfo inside")

  // const Payclasses = globalStyles();
  const Payclasses = PayHistory();

  const backtopayment = () => {
    // setClubPaymentHistory(false);
    
    props.response(false);
  };


  console.log(props.commoninformation, "common information");
  console.log(props.commoninformation.customerId, "common information");

  let customerid: string = props.commoninformation.customerId
  let Cocustomerid: string = props.commoninformation.coCustomerId


  const redirectionToCustomer = (type: string) => {
    console.log(type)
    console.log(history)
    console.log()
    if (type == "customer") {
      const response = {
        pathname: `/customer/update/${customerid}/customer`,
      }
      props.redirect(response)
    } else {
      const response = {
        pathname: `/customer/update/${Cocustomerid}/customer`,
      }
      props.redirect(response)
    }
  };




  const addressDoctorPopup = () => {
    const ClubActivityHead = () => (
      <>
        <RACTableCell className={Payclasses.payHistp2}>
          Activity Date
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Activity Type
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Status</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Co-worker</RACTableCell>
      </>
    );
    const ClubActivityContent = () => {
      return props.clubActivityInfo.map((value:ClubActivityInfo, index: number) => {
        return (
          <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                color="primary"
                className={`${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.activityDate}
              </Typography>
            </RACTableCell>

            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.activityType}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.status}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                {value.coWorker}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        );
      });
    };
    const getCustDetail = () => {
      let arr: customerDetails[] = []
      if (props?.customerData) {
        let obj: customerDetails = {
          customerId: props?.customerData?.customerId,
          customerName: props?.customerData?.customerDetails[0].firstName + CONSTANTS.STRING_WITH_SINGLE_SPACE+ props?.customerData?.customerDetails[0].lastName,
          phoneNumber: props?.customerData?.customerContactDetails[0]?.phoneNumber,
          emailId: props?.customerData?.customerDetails[0]?.emailAddress
        }
        arr.push(obj)
      }
      return arr;
      // setcustomerData(arr)
    }

    const ItemInfoHead2 = () => (
      <>
        <RACTableCell className={Payclasses.payHistp2}>Date Paid</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Day Paid</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Time Paid</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Receipt #</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Old Due Date
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Days Late</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Next Due Date
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Store</RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Payment Origin
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Tender Type
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>
          Total Payment
        </RACTableCell>
        <RACTableCell className={Payclasses.payHistp2}>Tax</RACTableCell>
      </>
    );

    const ItemInfoContent2 = () => {
      console.log(props.ClubGridInfoResp, "GRID CLUB information");
      return props.ClubGridInfoResp.map((value:ClubPaymentHistoryGridInfo, index: number) => {
        return (
          <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.datePaid}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.dayPaid}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.timePaid}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>


              <Typography
                variant="caption"
                color="primary"
                className={`${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                <a
                  style={{ cursor: "pointer" }}
                  title={value?.customerName ?? CONSTANTS.EMPTY_STRING}
                  onClick={() => ReceiptPopup(value.receiptId)}
                >
                  {value.receiptId}
                </a>
              </Typography>


            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                {value.oldDueDate}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                {value.daysLate}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.nextDueDate}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.store}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {value.paymentOrigin}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                {value.tenderType}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                ${Number(value.totalPayment).toFixed(2) ? Number(value.totalPayment).toFixed(2) : value.totalPayment}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
            <RACTableCell className={Payclasses.payHistp2}>
              <Typography
                variant="caption"
                className={`${Payclasses.payHisttextGrey} ${Payclasses.payHistfs7} ${Payclasses.payHistfontSemiBold}`}
              >
                {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                ${Number(value.tax).toFixed(2) ? Number(value.tax).toFixed(2) : value.tax}{CONSTANTS.STRING_WITH_SINGLE_SPACE}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        );
      });
    };

    return (
      <>


        <RACModalCard
          isOpen={viewReceiptPopup}
          className={classes.customModal}
          maxWidth="lg"
          title={'Receipt'}
          // eslint-disable-next-line react/no-children-prop
          children={receiptPopUp()}
          closeIcon={true}
          onClose={() => setviewReceiptPopup(false)}
        />
        <RACModalCard
          isOpen={viewExtPopup}
          className={classes.customModal}
          maxWidth="lg"
          title={'Extension Form'}
          // eslint-disable-next-line react/no-children-prop
          children={EXTPopUp()}
          closeIcon={true}
          onClose={() => setviewExtPopup(false)}
        />


        <RACModalCard
          isOpen={viewCooPopup}
          className={classes.customModal}
          maxWidth="lg"
          title={'Certificate of Ownership'}
          // eslint-disable-next-line react/no-children-prop
          children={cooPopUp()}
          closeIcon={true}
          onClose={() => setviewCooPopup(false)}
        />



        {masterloader1 ? (
          <Grid
            style={{
              position: "fixed",
              background: "#f7f5f5",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: "center",
              margin: "0px 0px",
            }}
          >
            <Grid
              style={{
                display: "block",
                position: "fixed",
                zIndex: 9999999,
                top: "40%",
                right: "50%",
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}


        {
          enablesuccesspopup ? (
            <RACModalCard
              isOpen={enablesuccesspopup}
              maxWidth="xs"
              closeIcon={true}
              borderRadius="20px"
              // title="Modal title"
              onClose={() => setenablesuccesspopup(false)}
              // eslint-disable-next-line react/no-children-prop
              children={Success()}
            />
          ) : null
        }



        {
          enableServiceFailpopup ? (
            <RACModalCard
              isOpen={enableServiceFailpopup}
              maxWidth="xs"
              closeIcon={true}
              borderRadius="20px"
              // title="Modal title"
              onClose={() => setenableServiceFailpopup(false)}
              // eslint-disable-next-line react/no-children-prop
              children={Service()}
            />
          ) : null
        }
        <Grid>
          <Grid className={Payclasses.payHistm2}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Typography
                  className={`${Payclasses.payHistfs5} ${Payclasses.payHistfontBold}`}
                >
                  Club Payment
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Typography>Customer</Typography>
                <Typography
                  className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistmt2}`}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectionToCustomer("customer")}
                  >
                    {props.commoninformation.customerName}
                  </a>                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Typography>Co-customer</Typography>
                <Typography
                  className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistmt2}`}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectionToCustomer("Cocustomer")}
                  >
                    {props.commoninformation.coCustomerName || CONSTANTS.HYPHEN}
                  </a>

                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} className={Payclasses.payHistmb3}>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <Grid className={Payclasses.payHistm2}>
                  <Typography variant="h5">Club Information</Typography>
                </Grid>
                <Grid
                  className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
                >
                  <Grid
                    container
                    className={Payclasses.payHistjustifySpaceBetween}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={7}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            Membership ID :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            {props.clubinfo.membershipId || "NA"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={6}
                      lg={5}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            {props.clubinfo.paymentSchedule} Rate :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            ${props.clubinfo.scheduleRate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={7}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            Enrollment Date :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            {props.clubinfo.enrollmentDate || "NA"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={5}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            Club Paid Till Date :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            {CONSTANTS.STRING_WITH_SINGLE_SPACE}
                            ${props.clubinfo.clubPaidTillDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={7}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            Termination Date :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            {props.clubinfo.terminationDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={5}
                      className={Payclasses.payHistmb2}
                    >
                      <Grid container>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                          >
                            Enrolled in Autopay :{CONSTANTS.STRING_WITH_SINGLE_SPACE}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                          >
                            {props.clubinfo.enrolledInAutopay}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                className={Payclasses.payHistmb4}
              >
                <Grid className={Payclasses.payHistm2}>
                  <Typography variant="h5">Club Activity</Typography>
                </Grid>
                <Grid className={`${Payclasses.payHistm2} `}>
                  <RACTable
                    renderTableHead={ClubActivityHead}
                    renderTableContent={ClubActivityContent}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid className={Payclasses.payHistm2}>
                <Typography variant="h5">Club Payment History</Typography>
              </Grid>
              <Grid className={`${Payclasses.payHistm2} `}>
                <InfiniteScroll
                  height={400}
                  next={() =>{
                    props.getClubWiseAllData();
                  }}
                  dataLength={props.ClubGridInfoResp?.length}
                  hasMore={props.hasMoreClubRcp}
                  loader={
                    props.scrollLoader ? (
                      <Grid className={Payclasses.GridLoader}>
                        <CircularProgress></CircularProgress>
                      </Grid>
                    ) : null
                  }
                >
                  <RACTable
                    renderTableHead={ItemInfoHead2}
                    renderTableContent={ItemInfoContent2}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={` ${Payclasses.payHistm2} ${Payclasses.payHistmt3} ${Payclasses.payHistp2} ${Payclasses.payHistcomponentRight}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              className={Payclasses.payHistmr2}
              onClick={() => backtopayment()}
            >
              Back
            </RACButton>
            {props.activateClubFeatureFlag==1?
            <RACButton
              variant="contained"
              color="primary"
              className={Payclasses.payHistmr2}
              onClick={() => setcustomerSignFlow(true)}
              disabled={clubDocumentSignStatus}
            >
              Sign Club Document
            </RACButton>:null}

            <RACButton
              variant="contained"
              color="primary"
              className={Payclasses.payHistmr2}
              onClick={() => {
                printHTML(document.getElementById('ClubAgreementPrintInfo'));
              }}
            >
              Print
            </RACButton>

            {/* <RACButton variant="contained" color="primary" className={Payclasses.payHistmr2}>Send Email</RACButton> */}
            {/* <RACButton variant="contained" color="primary" className={Payclasses.payHistmr2}>Print</RACButton> */}
          </Grid>
        </Grid>
        <ClubHistoryPrintInfo
          clubActivityInfo={props.clubActivityInfo}
          ClubGridInfoResp={props.ClubGridInfoResp}
          commoninformation={props.commoninformation}
          clubinfo={props.clubinfo}
        />
        {customerSignFlow?
        <CustomerSignature
        customerSignUsingField="club"
        customerSignUsingModule="payment"
        identifier={parseInt(props?.customerData?.data?.GetCustomer?.value?.customerId)}
        customersArr={getCustDetail()}
        manualSignOptions={ManualSignOptions}
        documentTypeArr={['CLUB']}
        customerSignatureCompletedFn={() =>setcustomerSignFlow(false)}
        customerSignatureCanceledFn={() =>setcustomerSignFlow(false)}
        documentGenerateCompleted={true}
      /> : null}


      </>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={props.openModal}
        maxWidth="lg"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => props.closeClubPaymentHistoryPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title=""
      />
    </>
  );
}


