/*eslint-disable*/
/**PSC_CO_NO_13 - PSC_CO_NO_14
 * Required hooks and the racstrap component are imported
 */
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../../context/PaymentInformationContext';
import {
  calculateModule,
  otherIncomeResponseFormation,
  scheduleReaasonOptionFormation,
} from '../../../utils/utils';
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
  RACTable,
  RACTableCell,
  RACSelect,
  RACTableRow,
  RACTextbox,
} from '@rentacenter/racstrap';
import { calculatePaymentModule } from '../../../api/user';
import {
  ChargeOffDetails,
  DropDownArray,
} from '../../interface/commonInterface';
import CONSTANTS from '../../constants/constant';
import { AgreementDetails } from '../../interface/paymentInfoInterface';
import { ChargeOffStyles } from '../../../stylesJS/ChargeOffStyles';
import { AmountDetails, PaymentDetails } from '../../interface/calculatePaymentResponse';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
//UI

interface FieldsDisable {
  isContinueDisable: boolean;
  isReasonDropDisable: boolean;
  isValidationShown: boolean;
}
interface ChargeOffComponentProps {
  isOpen: boolean;
  isClose: (res: boolean) => void;
  editChargeoffDatas?: ChargeOffDetails[];
  customerId: string;
}
export function ChargeOffComponent(props: ChargeOffComponentProps) {
  /**PSC_CO_NO_15
   * Context and state variable declaration
   */
  const {
    paymentSchedule,
    otheIncomeChargeOffDetails,
    setOtheIncomeChargeOffDetails,
    agreementDetails,
    setAgreementDetails,
    setRenderContext,
    renderContext,
  } = useContext(agreementContext);
  const [chargeOffData, setChargeOffData] = useState<ChargeOffDetails[]>([
    {
      agreementId: CONSTANTS.EMPTY_STRING,
      agreementNumber: CONSTANTS.EMPTY_STRING,
      closeDate: CONSTANTS.EMPTY_STRING,
      epoAmountOwed: CONSTANTS.ZERO_DECIMAL_NUMBER,
      epoAmountRemaining: CONSTANTS.ZERO_DECIMAL_NUMBER,
      epoAmountWithtax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      reason: CONSTANTS.EMPTY_STRING,
      reasonRefcode: CONSTANTS.EMPTY_STRING,
      taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    },
  ]);
  const [reasonDropdown, setreasonDropdown] = useState<DropDownArray[]>();
  const [acceptSpinner, setacceptSpinner] = useState<boolean>(false);
  const [fieldsEnableDisableFlag, setFieldsEnableDisableFlag] =
    useState<FieldsDisable>({
      isContinueDisable: false,
      isReasonDropDisable: false,
      isValidationShown: false,
    });
  const reasonDropDownArray: DropDownArray[] = [
    { label: CONSTANTS.SELECT, value: CONSTANTS.SELECT },
  ];
  const classes = takePaymentStyles();
  const classes1 = ChargeOffStyles();
  const [reasonDropDownDisableFlag, setReasonDropDownDisableFlag] = useState<boolean>(false)

  /**PSC_CO_NO_16 - PSC_CO_NO_19 This useEffect used to render the form the Reason drop-down values */
  useEffect(() => {

    let obj = [] as ChargeOffDetails[];
    if (props.editChargeoffDatas) {
      obj = JSON.parse(JSON.stringify(props.editChargeoffDatas));
      setChargeOffData(props.editChargeoffDatas);
      setReasonDropDownDisableFlag(true);
    } else if (otheIncomeChargeOffDetails) {
      obj = JSON.parse(JSON.stringify(otheIncomeChargeOffDetails));
      setChargeOffData(obj);
    }

    scheduleReaasonOptionFormation(
      paymentSchedule,
      CONSTANTS.AGREEMENT_STATUS_REASON
    )?.forEach((x) => {
      const obj = {
        label: x.description,
        value: x.referenceCode,
      };
      reasonDropDownArray.push(obj);
    });
    setreasonDropdown(reasonDropDownArray);
  }, [props.editChargeoffDatas]);

  /**PSC_CO_NO_27 - PSC_CO_NO_28
   * This useEffect helps to enable and disable the continue button and also if the edited amount is
   * greater than epoAmountWithtax then the validation message is shown.
   */
  useEffect(() => {
    const isFlagValidationTrue = chargeOffData.every(
      (x: ChargeOffDetails) => Number(x.amount) <= Number(x.epoAmountWithtax)
    );
    const isFlagTrue = chargeOffData.some(
      (x) =>
        Number(x.amount) != 0.0 &&
        Number(x.amount) != CONSTANTS.ZERO_NUMBER &&
        x.amount != CONSTANTS.EMPTY_STRING &&
        x.reason != CONSTANTS.SELECT &&
        x.reason != CONSTANTS.EMPTY_STRING
    );
    setFieldsEnableDisableFlag({
      ...fieldsEnableDisableFlag,
      isContinueDisable: !isFlagTrue,
      isValidationShown: !isFlagValidationTrue,
    });
  }, [chargeOffData]);

  /**PSC_CO_NO_22 to PSC_CO_NO_24
   * The handlechangeChargeoff() method is invoked when the amount is entered in the Charge-off input fields
   */
  const handlechangeChargeoff = (
    val:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    name: string,
    index: number
  ) => {
    const chargeoff = [...chargeOffData];
    let Reason = CONSTANTS.EMPTY_STRING;
    let removeComma: string;
    switch (name) {
      case CONSTANTS.AMOUNT_UPPER_CASE:
        removeComma = String(val.target.value).replace(
          CONSTANTS.REPLACE_COMMA,
          CONSTANTS.EMPTY_STRING
        );
        chargeoff[index].amount = removeComma;
        break;
      case CONSTANTS.REASON:
        Reason =
          CONSTANTS.REASONMAPPING[val.target.value] || CONSTANTS.EMPTY_STRING;
        chargeoff[index].reason = Reason;
        chargeoff[index].reasonRefcode = val.target.value;
        break;
    }
    setChargeOffData(chargeoff);
  };
  /**PSC_CO_NO_25 - PSC_CO_NO_26
   * The handleOnblur() method is invoked when the user click outside the text fields
   */
  const handleOnblur = (
    val: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const chargeoff: ChargeOffDetails[] = [...chargeOffData];
    const value = String(val.target.value).replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    chargeoff[index].amount =
      value == CONSTANTS.EMPTY_STRING ||
        parseFloat(value) == CONSTANTS.ZERO_NUMBER
        ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
        : Number(value).toFixed(2);
    setChargeOffData(chargeoff);
  };

  /**PSC_CO_NO_29 - PSC_CO_NO_32
   * The continueOnclick() method is invoked when the Co-worker clicks the continue button after editing the text fields.
   */
  const continueOnclick = async () => {
    let respData: ChargeOffDetails[] = [];
    const chargeoff: ChargeOffDetails[] = [...chargeOffData];
    const storeNumber = String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER));
    respData = revalidateChargeOffData(chargeoff);
    setacceptSpinner(true);
    const commonModuleServiceRes = await calculateModule(
      props.customerId,
      storeNumber,
      respData
    );

    const commonModuleAgrRes: PaymentDetails[] = commonModuleServiceRes?.paymentDetails.filter(
      (x: PaymentDetails) =>
        x.storeNumber ==
        storeNumber
    );

    const calculateModuleSkipStolen =
      commonModuleAgrRes[0].otherIncome?.filter(
        (x: AmountDetails) => x.itemCategory == CONSTANTS.RESTITPAY
      );

    respData.forEach((x: ChargeOffDetails) => {
      const agrRes = calculateModuleSkipStolen?.find(
        (el: AmountDetails) => String(el.agreementId) == String(x.agreementId)
      );
      x.amount = Number(Number(agrRes?.amount) + Number(agrRes?.tax)).toFixed(2);
      x.newTax = Number(agrRes?.tax).toFixed(2);
      x.amountWithoutTax = Number(agrRes?.amount).toFixed(2);
    });
    updateAgreementDetails(respData);
    setacceptSpinner(false);
    props.isClose(false);
    setRenderContext(!renderContext);
  };

  const cancelOnclick = () => {
    setChargeOffData([
      {
        agreementId: CONSTANTS.EMPTY_STRING,
        agreementNumber: CONSTANTS.EMPTY_STRING,
        closeDate: CONSTANTS.EMPTY_STRING,
        epoAmountOwed: CONSTANTS.ZERO_DECIMAL_NUMBER,
        epoAmountRemaining: CONSTANTS.ZERO_DECIMAL_NUMBER,
        epoAmountWithtax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        reason: CONSTANTS.EMPTY_STRING,
        reasonRefcode: CONSTANTS.EMPTY_STRING,
        taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      },
    ])
    props.isClose(false)
  }

  const updateAgreementDetails = async (respData: ChargeOffDetails[]) => {
    let agreementDetailsCopy: AgreementDetails[] = JSON.parse(JSON.stringify(agreementDetails));

    for (const el of respData) {
      const index = agreementDetailsCopy.findIndex(
        (x: AgreementDetails) => x.agreementId == el.agreementId
      );
      if (index != CONSTANTS.ONE_NEGATIVE_NUMBER) {
        // setAgreementDetails((prevDetails: AgreementDetails[]) =>
        agreementDetailsCopy[index] = {
          ...agreementDetailsCopy[index],
          reasonRefcode: el.reasonRefcode ?? CONSTANTS.EMPTY_STRING,
          agreementDesc: el.reason ?? CONSTANTS.EMPTY_STRING,
          totalDueAmount: el.amount ?? CONSTANTS.EMPTY_STRING,
          totalTax: el.newTax ?? CONSTANTS.EMPTY_STRING,
        };
        // );
      } else {
        const otherIncomeAgr: AgreementDetails[] = await otherIncomeResponseFormation(
          [el]
        );
        // setAgreementDetails([...agreementDetails, ...otherIncomeAgr]);
        if (agreementDetails.length == CONSTANTS.ONE_NUMBER && agreementDetails[0].agreementId == CONSTANTS.EMPTY_STRING)
          agreementDetailsCopy = otherIncomeAgr
        else
          agreementDetailsCopy = [...agreementDetailsCopy, ...otherIncomeAgr];
        console.log('otherIncomeAgr', otherIncomeAgr);
      }
    };
    setAgreementDetails(agreementDetailsCopy);
  };


  const revalidateChargeOffData = (chargeoff: ChargeOffDetails[]) => {
    const respDataNew: ChargeOffDetails[] = [];
    chargeoff.map((x, index) => {
      if (
        x.amount == CONSTANTS.ZREO_TWO_DECIMAL_STRING ||
        x.reason == CONSTANTS.SELECT ||
        x.reason == CONSTANTS.EMPTY_STRING
      ) {
        chargeoff[index].amount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        chargeoff[index].reason = CONSTANTS.SELECT;
      } else if (
        x.amount != CONSTANTS.ZREO_TWO_DECIMAL_STRING &&
        x.reason != CONSTANTS.SELECT &&
        x.reason != CONSTANTS.EMPTY_STRING
      ) {
        const splitTax = Number(x.taxRate) + CONSTANTS.ONE_NUMBER;
        const amountWithoutTax = (Number(x.amount) / splitTax).toFixed(2);
        const newTax = (Number(x.amount) - Number(amountWithoutTax)).toFixed(2);
        x.amountWithoutTax = amountWithoutTax;
        x.newTax = newTax;
        x.type = CONSTANTS.RESTITUTION;
        respDataNew.push(x);
        setOtheIncomeChargeOffDetails(prevDetails =>
          prevDetails.map(detail =>
            detail.agreementNumber === x.agreementNumber ? x : detail
          ))
      }
    });
    setChargeOffData(chargeoff);

    // setOtheIncomeChargeOffDetails(chargeoff);
    return respDataNew;
  };

  const popup = () => {
    return (
      <Grid>
        <Grid>
          <RACTable
            className={classes.racGrid}
            renderTableHead={chargeoffgridHeader}
            renderTableContent={chargeoffgridContent}
          />
        </Grid>
        <Grid
          style={{ marginRight: "-2px" }}
          className={`${classes.floatRight} ${classes1.grid1}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classes.me1} ${classes.foc} ${classes1.btnmargin}`}
            onClick={() => cancelOnclick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.me1}  ${classes1.btnmargin}`}
            disabled={
              !fieldsEnableDisableFlag.isContinueDisable &&
                !fieldsEnableDisableFlag.isValidationShown
                ? false
                : true
            }
            loading={acceptSpinner}
            onClick={() => continueOnclick()}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**PSC_CO_NO_20
   * The chargeoffgridHeader() method used to render the headers of the charge-off popup
   */
  const chargeoffgridHeader = () => {
    return (
      <>
        <RACTableCell>Agreement #</RACTableCell>
        <RACTableCell>Close Date</RACTableCell>
        <RACTableCell style={{ textAlign: "right" }}>
          EPO Amount Owed
        </RACTableCell>
        <RACTableCell style={{ textAlign: "right" }}>
          EPO Amount Remaining
        </RACTableCell>
        <RACTableCell style={{ textAlign: "right" }}>
          <p style={{ marginTop: "30px" }}>EPO Amount Remaining</p>
          <p style={{ marginTop: "-20px", marginLeft: "-0px" }}>
            (Including Tax)
          </p>
        </RACTableCell>
        <RACTableCell style={{ textAlign: "center" }}>
          Amount Collected<span style={{ color: "red" }}>*</span>
        </RACTableCell>
        <RACTableCell>
          Reason<span style={{ color: "red" }}>*</span>
        </RACTableCell>
      </>
    );
  };

  /**PSC_CO_NO_21
   * The chargeoffgridContent() method used to Render the table content in the charge-off popup.
   */
  const chargeoffgridContent = () => {
    return (
      <>
        {chargeOffData.map((val, index) => {
          return (
            <>
              <RACTableRow
                className={`${classes.tablerowtheme} ${classes.font12}`}
              >
                <RACTableCell>{val.agreementNumber}</RACTableCell>
                <RACTableCell>
                  {val.closeDate == null ||
                    val.closeDate == CONSTANTS.EMPTY_STRING
                    ? CONSTANTS.HYPHEN
                    : val.closeDate}
                </RACTableCell>
                <RACTableCell style={{ textAlign: "right" }}>
                  {CONSTANTS.DOLLAR + val.epoAmountOwed}
                </RACTableCell>
                <RACTableCell style={{ textAlign: "right" }}>
                  {CONSTANTS.DOLLAR + val.epoAmountRemaining}
                </RACTableCell>
                <RACTableCell style={{ textAlign: "right" }}>
                  {CONSTANTS.DOLLAR + val.epoAmountWithtax}
                </RACTableCell>
                <RACTableCell style={{ float: "right", marginRight: "-10%" }}>
                  <RACTextbox
                    className={classes1.dollartext3}
                    dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                    type="number"
                    name={`Amount_${index}`}
                    id={String(index)}
                    value={val.amount}
                    // value={chargeOffData.Amount}
                    OnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handlechangeChargeoff(
                        e,
                        CONSTANTS.AMOUNT_UPPER_CASE,
                        index
                      );
                    }}
                    Onblur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnblur(e, index);
                    }}
                    isCurrency={true}
                    maxlength={10}
                    required={false}
                  />

                  <Typography
                    hidden={
                      Number(val.amount) > Number(val.epoAmountWithtax)
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                    variant="h6"
                  >
                    <p style={{ marginTop: "1px", fontSize: 'smaller' }}>Enter amount less than</p>
                    <p style={{ marginTop: "-15px", marginBottom: "-10px", fontSize: 'smaller' }}>
                      EpoAmount IncludingTax
                    </p>
                  </Typography>
                </RACTableCell>

                <RACTableCell style={{ width: "12% " }}>
                  <RACSelect
                    name={`Reason_${index}`}
                    defaultValue={val.reasonRefcode}
                    data-testid='cancel'
                    isDisabled={reasonDropDownDisableFlag}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      handlechangeChargeoff(e, CONSTANTS.REASON, index)
                    }
                    options={reasonDropdown}
                  />
                </RACTableCell>
              </RACTableRow>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={props.isOpen}
        borderRadius="20px"
        maxWidth="lg"
        className={classes1.modalcard}
        // children={popup()}
        title={CONSTANTS.SKIP_STOLEN_PAYMENT}
      >
        {popup()}
      </RACModalCard>
    </>
  );
}
