/*eslint-disable no-console*/
/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_HFS_01
 * Import the required packgaes, interface and context
 */
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACTextbox,
  RACButton,
  RACModalCard,
  RACTable,
  RACTableRow,
  RACTableCell,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { PayHistory } from '../../stylesJS/PayHistory';
import CONSTANTS from '../constants/constant';
import { AgreementContextValue } from '../interface/contextInterface';
import { FinalSuspPopups, SuspenseProps } from '../interface/suspenseInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { schedule } from '../utils/scheduleAndDateFormat';

/**
 * PS_HFS_02 - PS_HFS_58
 * This function holds final suspense calculations and popup
 * @returns
 */
export function HandleFinalSuspense(props: SuspenseProps) {
  /**
   * PS_HFS_03 - PS_HFS_05
   * Declaring variables and destructing from context
   */
  const classes = takePaymentStyles();

  const {
    agreementDetails,
    setAgreementDetails,
    customerInfo,
    renderContext,
    setRenderContext,
    isSetToDefault,
    featureFlags,
  } = useContext<AgreementContextValue>(agreementContext);

  const [agreementDetailsClone, setAgreementDetailsClone] = useState<
    AgreementDetails[]
  >(JSON.parse(JSON.stringify(agreementDetails)));

  const [agreementWithSuspEpoAmtDue, setAgreementWithSuspEpoAmtDue] = useState<
    string[]
  >([]);

  const [agreementWithOverSusp, setAgreementWithOverSusp] = useState<string[]>(
    []
  );

  const [agreementWithExactSusp, setAgreementWithExactSusp] = useState<
    string[]
  >([]);

  const [agreementDescription, setAgreementDescription] = useState<string[]>(
    []
  );

  const [finalSuspPopups, setFinalSuspPopups] = useState<FinalSuspPopups>({
    suspEpoAmtDuePopup: false,
    overSusPopup: false,
    congratsPopup: false,
    finalTransferGrid: false,
  });

  const [testSusp, setTestSusp] = useState<boolean>(false);

  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);

  let eligibleTransferAgrementsCount = CONSTANTS.ZERO_NUMBER,
    allAgreementOverSuspenseCount = CONSTANTS.ZERO_NUMBER;

  const agreementWithSuspEpoAmtDueArray: string[] = [],
    agreementWithOverSuspArray: string[] = [],
    agreementWithExactSusArray: string[] = [],
    agreementDesc: string[] = [],
    nonEnableArray: string[] = [];

  const sameStoreAgreements = agreementDetailsClone.filter(
    (el: AgreementDetails) =>
      el.agreementType.includes(CONSTANTS.RENTAL_AGREEMENT_RTO) &&
      el.selected &&
      el.storeNumber == storeNumber
    // && Number(el.suspenseAmount) > CONSTANTS.ZERO_NUMBER
  );

  /**
   * PS_HFS_06
   * Within useEffect, call finalTransferSuspense()
   */
  useEffect(() => {
    if (featureFlags.FinalPaymentTransferSuspense) finalTransferSuspense();
    else nonEnableTransferFlow();
  }, [agreementDetailsClone]);

  useEffect(() => {
    if (isSetToDefault > 0) {
      setTestSusp(false); //To display the suspense popup when set t default is clicked
      setAgreementDetailsClone(JSON.parse(JSON.stringify(agreementDetails)));
    }
  }, [isSetToDefault]);

  useEffect(() => {
    setTestSusp(true);
    if (props.popupValue == CONSTANTS.SUSPENSE_POPUPS && testSusp)
      props.setPopupValue(CONSTANTS.RACEXCHANGE_POPUPS);
  }, [props.popupValue]);
  /**
   * PS_HFS_07 - PS_HFS_22
   * Declare a finalTransferSuspense()
   * calculates suspense amount and epo amount to get final transfer eligible agreements
   */
  async function finalTransferSuspense() {
    console.log('sameStoreAgreements', sameStoreAgreements);

    await suspenseAgreementSegregation(sameStoreAgreements);

    console.log(
      'agreementWithSuspEpoAmtDueArray',
      agreementWithSuspEpoAmtDueArray,
      'agreementWithOverSuspArray',
      agreementWithOverSuspArray,
      'agreementWithExactSusArray',
      agreementWithExactSusArray
    );

    console.log(
      'eligibleTransferAgrementsCount',
      eligibleTransferAgrementsCount,
      'allAgreementOverSuspenseCount',
      allAgreementOverSuspenseCount
    );

    setAgreementWithSuspEpoAmtDue(agreementWithSuspEpoAmtDueArray);
    setAgreementWithOverSusp(agreementWithOverSuspArray);
    setAgreementWithExactSusp(agreementWithExactSusArray);
    setAgreementDescription(agreementDesc);
    if (
      agreementWithOverSuspArray.length &&
      eligibleTransferAgrementsCount > CONSTANTS.ONE_NUMBER &&
      eligibleTransferAgrementsCount != allAgreementOverSuspenseCount &&
      agreementWithExactSusArray.length != eligibleTransferAgrementsCount
    ) {
      setFinalSuspPopups((prev) => ({ ...prev, overSusPopup: true }));
    } else if (
      agreementWithSuspEpoAmtDueArray.length &&
      agreementWithExactSusArray.length == CONSTANTS.ZERO_NUMBER &&
      agreementWithOverSuspArray.length == CONSTANTS.ZERO_NUMBER
    ) {
      setFinalSuspPopups((prev) => ({ ...prev, suspEpoAmtDuePopup: true }));
    } else if (
      (agreementWithOverSuspArray.length &&
        agreementWithExactSusArray.length &&
        agreementWithExactSusArray.length == eligibleTransferAgrementsCount) ||
      agreementWithExactSusArray.length
    ) {
      setFinalSuspPopups((prev) => ({ ...prev, congratsPopup: true }));
    }

    if (
      (!agreementWithOverSuspArray.length ||
        eligibleTransferAgrementsCount == allAgreementOverSuspenseCount) &&
      !agreementWithSuspEpoAmtDueArray.length &&
      !agreementWithExactSusArray.length
    ) {
      setAgreementDetails(agreementDetailsClone);
      setRenderContext(!renderContext);
      props.popupValue == CONSTANTS.SUSPENSE_POPUPS &&
        props.setPopupValue(CONSTANTS.RACEXCHANGE_POPUPS);
    }
  }

  async function nonEnableTransferFlow() {
    await suspenseAgreementSegregation(sameStoreAgreements);

    if (nonEnableArray.length) {
      const array: AgreementDetails[] = agreementDetailsClone.map(
        (el: AgreementDetails) => {
          if (nonEnableArray.includes(el.agreementNumber)) {
            return {
              ...el,
              suspenseDetails: {
                ...el.suspenseDetails,
                suspUse: el.suspenseAmount,
              },
              nextDueDate:
                new Date(el.currentDueDate) <= new Date(el.sacDate)
                  ? CONSTANTS.SAC
                  : CONSTANTS.EPO,
              agreementType: CONSTANTS.EPO_RENT,
              acceptEpoFlag: CONSTANTS.ONE_NUMBER,
            };
          } else return el;
        }
      );
      setAgreementDetails(array);
    } else {
      props.setPopupValue(CONSTANTS.RACEXCHANGE_POPUPS);
    }
  }

  console.log('finalSusppppp', finalSuspPopups);

  function calculateSuspAmount(agreement: AgreementDetails) {
    return (
      Number(agreement.suspenseAmount) +
      Number(agreement.suspenseDetails?.transferSusAdd || 0) -
      Number(agreement.suspenseDetails?.transferSusUse || 0)
    );
  }

  function handleOverSuspense(agreement: AgreementDetails) {
    if (
      Number(agreement.suspenseAmount) > Number(agreement.epoAmount) &&
      !(CONSTANTS.IS_OVER_SUSPENSE_KEY in agreement)
    ) {
      agreementWithOverSuspArray.push(agreement.agreementNumber);
      if (!nonEnableArray.includes(agreement.agreementNumber)) {
        nonEnableArray.push(agreement.agreementNumber);
      }
      allAgreementOverSuspenseCount++;
    }
  }

  function handleSuspEpoAmtDue(
    agreement: AgreementDetails,
    suspAmount: number
  ) {
    if (
      Number(suspAmount) < Number(agreement.epoAmount) &&
      Number(suspAmount) >=
        Number(agreement.epoAmount) -
          (Number(agreement.totalAmount) + Number(agreement.totalTax)) &&
      !(CONSTANTS.IS_EPO_SUS_AMT_DUE_KEY in agreement)
    ) {
      agreementWithSuspEpoAmtDueArray.push(agreement.agreementNumber);
      if (!nonEnableArray.includes(agreement.agreementNumber)) {
        nonEnableArray.push(agreement.agreementNumber);
      }
    }
  }

  function handleExactSuspense(
    agreement: AgreementDetails,
    suspAmount: number
  ) {
    if (
      Number(suspAmount) >= Number(agreement.epoAmount) &&
      !(CONSTANTS.IS_EXACT_SUSPENSE_KEY in agreement) &&
      !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(agreement.nextDueDate)
    ) {
      agreementWithExactSusArray.push(agreement.agreementNumber);
      agreementDesc.push(agreement.agreementDesc);
      if (!nonEnableArray.includes(agreement.agreementNumber)) {
        nonEnableArray.push(agreement.agreementNumber);
      }
    }
  }

  async function suspenseAgreementSegregation(
    sameStoreAgreements: AgreementDetails[]
  ) {
    sameStoreAgreements.forEach((eachAgreement: AgreementDetails) => {
      const suspAmount = calculateSuspAmount(eachAgreement);

      handleOverSuspense(eachAgreement);

      handleSuspEpoAmtDue(eachAgreement, suspAmount);

      handleExactSuspense(eachAgreement, suspAmount);

      eligibleTransferAgrementsCount++;
    });
  }

  /**
   * PS_HFS_24 - PS_HFS_30
   * This function handles the yes click in epoWithSuspenseAmountDue
   */
  function makingEPOWithSusEPOAmountDueYes() {
    const makingEPOWithSusEPOAmountDueYesData = agreementDetailsClone.map(
      (eachAgreement: AgreementDetails) => {
        if (
          agreementWithSuspEpoAmtDue.includes(eachAgreement.agreementNumber)
        ) {
          return {
            ...eachAgreement,
            suspenseDetails: {
              ...eachAgreement.suspenseDetails,
              suspUse: eachAgreement.suspenseAmount,
            },
            isEPOSusAmtDue: true,
            nextDueDate:
              new Date(eachAgreement.currentDueDate) <=
              new Date(eachAgreement.sacDate)
                ? CONSTANTS.SAC
                : CONSTANTS.EPO,
            agreementType: CONSTANTS.EPO_RENT,
            acceptEpoFlag: CONSTANTS.ONE_NUMBER,
          };
        } else return eachAgreement;
      }
    );

    setAgreementDetailsClone(makingEPOWithSusEPOAmountDueYesData);

    setFinalSuspPopups((prev) => ({ ...prev, suspEpoAmtDuePopup: false }));
  }

  /**
   * PS_HFS_32 - PS_HFS_39
   * This function handles no click in epoWithSuspenseAmountDue
   */
  function makingEPOWithSusEPOAmountDueNo() {
    const makingEPOWithSusEPOAmountDueNoData = agreementDetailsClone.map(
      (eachAgreement: AgreementDetails) => {
        if (
          agreementWithSuspEpoAmtDue.includes(eachAgreement.agreementNumber)
        ) {
          return {
            ...eachAgreement,
            isEPOSusAmtDue: false,
            acceptEpoFlag: CONSTANTS.ZERO_NUMBER,
          };
        } else return eachAgreement;
      }
    );

    setAgreementDetailsClone(makingEPOWithSusEPOAmountDueNoData);

    setFinalSuspPopups((prev) => ({ ...prev, suspEpoAmtDuePopup: false }));
  }

  /**
   * PS_HFS_41 - PS_HFS_48
   * This function handles ok click in exact suspense
   */
  function makingEPOWithExactSusEPOOk() {
    const makingEPOWithExactSusData = agreementDetailsClone.map(
      (eachAgreement: AgreementDetails) => {
        if (agreementWithExactSusp.includes(eachAgreement.agreementNumber)) {
          return {
            ...eachAgreement,
            suspenseDetails: {
              ...eachAgreement.suspenseDetails,
              suspUse: eachAgreement.suspenseAmount,
            },
            nextDueDate:
              new Date(eachAgreement.currentDueDate) <=
              new Date(eachAgreement.sacDate)
                ? CONSTANTS.SAC
                : CONSTANTS.EPO,
            agreementType: CONSTANTS.EPO_RENT,
            isExactSus: true,
            acceptEpoFlag: CONSTANTS.ONE_NUMBER,
          };
        } else return eachAgreement;
      }
    );

    setAgreementDetailsClone(makingEPOWithExactSusData);

    setFinalSuspPopups((prev) => ({ ...prev, congratsPopup: false }));
  }

  /**
   * PS_HFS_57 - PS_HFS_58
   * This function handles yes click in overSuspense
   */
  function makingEPOWithOverSusYes() {
    setFinalSuspPopups((prev) => ({
      ...prev,
      overSusPopup: false,
      finalTransferGrid: true,
    }));
  }

  /**
   * PS_HFS_50 - PS_HFS_56
   * This function handles no click in overSuspense popup
   */
  function makingEPOWithOverSusNo() {
    const makingEPOWithOverSusNoData = agreementDetailsClone.map(
      (eachAgreement: AgreementDetails) => {
        if (agreementWithOverSusp.includes(eachAgreement.agreementNumber)) {
          return {
            ...eachAgreement,
            isOverSus: false,
            acceptEpoFlag: CONSTANTS.ZERO_NUMBER,
          };
        } else return eachAgreement;
      }
    );

    setAgreementDetailsClone(makingEPOWithOverSusNoData);

    setFinalSuspPopups((prev) => ({ ...prev, overSusPopup: false }));
  }

  /**
   * This function holds html for epoWithSuspenseAmountDue popup
   * @returns
   */
  const makingEpoWithSusEPOAmountDuePopup = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            data-testid="suspEpoAmtDuePopup"
          >
            Customer has enough suspense amount to EPO with this payment for
            this agreements {String(agreementWithSuspEpoAmtDue)} {''} would you
            like to payoff ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithSusEPOAmountDueNo()}
            data-bs-target="#delivery-receipt"
            data-testid="suspEpoNoBtn"
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithSusEPOAmountDueYes()}
            data-bs-target="#delivery-receipt"
            data-testid="suspEpoYesBtn"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * This function holds html for overSuspense popup
   * @returns
   */
  const makingEpoWithOverSusEPOPopup = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Suspense amount will cause agreement {String(agreementWithOverSusp)}{' '}
            {''}
            to payoff Do you want to transfer the remaining suspense amount ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithOverSusNo()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithOverSusYes()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * This function holds html for exact suspense popup
   * @returns
   */
  const makingEpoWithExactSusEPOPopup = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            data-testid="congratsPopup"
          >
            Congratulations,{' '}
            {customerInfo.customerDetails[0].firstName +
              ' ' +
              customerInfo.customerDetails[0].lastName}{' '}
            This is the final payment on your {String(agreementDescription)}{' '}
            {''}To celebrate, we want to offer you our current promotion for
            paid in full customers! Today is the only day you can take advantage
            of this offer, so what would you like to rent next?.
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithExactSusEPOOk()}
            data-bs-target="#delivery-receipt"
            data-testid="congratsOkBtn"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * Within return, it holds modal card for the popups
   */
  return (
    <>
      {' '}
      {props.popupValue == CONSTANTS.SUSPENSE_POPUPS && (
        <>
          {finalSuspPopups.suspEpoAmtDuePopup ? (
            <RACModalCard
              isOpen={finalSuspPopups.suspEpoAmtDuePopup}
              maxWidth="xs"
            >
              {makingEpoWithSusEPOAmountDuePopup()}
            </RACModalCard>
          ) : finalSuspPopups.overSusPopup ? (
            <RACModalCard isOpen={finalSuspPopups.overSusPopup} maxWidth="xs">
              {makingEpoWithOverSusEPOPopup()}
            </RACModalCard>
          ) : finalSuspPopups.congratsPopup ? (
            <RACModalCard isOpen={finalSuspPopups.congratsPopup} maxWidth="xs">
              {makingEpoWithExactSusEPOPopup()}
            </RACModalCard>
          ) : finalSuspPopups.finalTransferGrid ? (
            <RACModalCard
              isOpen={finalSuspPopups.finalTransferGrid}
              closeIcon={true}
              borderRadius={`${classes.boderRadius25}`}
              onClose={() =>
                setFinalSuspPopups((prev) => ({
                  ...prev,
                  overSusPopup: true,
                  finalTransferGrid: false,
                }))
              }
              maxWidth={'md'}
              title={'Transfer Suspense'}
            >
              <FinalTransferSuspense
                agreementWithOverSusp={agreementWithOverSusp}
                finalSuspPopups={finalSuspPopups}
                setFinalSuspPopups={setFinalSuspPopups}
                setAgreementDetailsCloneSetter={setAgreementDetailsClone}
              />
            </RACModalCard>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

/**
 * PS_HFS_60 - PS_HFS_96
 * This function is used to display the transfer grid popup
 * @param param0
 * @returns
 */
const FinalTransferSuspense = ({
  agreementWithOverSusp,
  finalSuspPopups,
  setFinalSuspPopups,
  setAgreementDetailsCloneSetter,
}) => {
  /**
   * PS_SC_42 - PS_SC_43
   * Declaring and initializing variables
   */
  const classes = takePaymentStyles();
  const payclasses = PayHistory();
  const { agreementDetails, customerInfo } =
    useContext<AgreementContextValue>(agreementContext);
  const [agreementDetailsClone, setAgreementDetailsClone] = useState<
    AgreementDetails[]
  >(JSON.parse(JSON.stringify(agreementDetails)));
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const [, setRenderer] = useState<boolean>(false);

  useEffect(() => {
    checkError();
  }, []);

  const checkError = () => {
    setAgreementDetailsClone(
      agreementDetailsClone.map((el: AgreementDetails) => {
        const epo = Number(el.epoAmount).toFixed(2);
        const suspAmount = Number(el.suspenseAmount).toFixed(2);
        if (Number(epo) < Number(suspAmount)) {
          el.error = CONSTANTS.AMOUNT_GREATER_THAN_EPO;
        }
        return el;
      })
    );
  };

  /**
   * PS_SC_57 - PS_SC_62
   * This function triggers in onChange of input field is clicked
   * @param e
   * @param param1
   * @param onBlur
   */
  function transferSusupense(
    e: React.ChangeEvent<HTMLInputElement>,
    { epoAmount, suspenseAmount },
    onBlur = false
  ) {
    const value = e.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const index = agreementDetailsClone.findIndex(
      (el) => el.agreementId == e.target.id
    );

    if (!onBlur)
      agreementDetailsClone[index].suspenseAmountNew = e.target.value;

    if (onBlur)
      agreementDetailsClone[index].suspenseAmountNew = agreementDetailsClone[
        index
      ].suspenseAmountNew
        ? Number(agreementDetailsClone[index].suspenseAmountNew).toFixed(2)
        : CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    if (Number(value) <= Number(epoAmount)) {
      delete agreementDetailsClone[index]?.error;

      if (onBlur && Number(value) > suspenseAmount)
        agreementDetailsClone[index].suspenseDetails.transferSusAdd = (
          Number(value) - Number(suspenseAmount)
        ).toFixed(2);
      else if (onBlur && Number(value) < suspenseAmount)
        agreementDetailsClone[index].suspenseDetails.transferSusUse = (
          Number(suspenseAmount) - Number(value)
        ).toFixed(2);
      else {
        agreementDetailsClone[index].suspenseDetails.transferSusUse =
          CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        agreementDetailsClone[index].suspenseDetails.transferSusAdd =
          CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      }
    } else
      agreementDetailsClone[index].error = CONSTANTS.AMOUNT_GREATER_THAN_EPO;

    setRenderer((prev) => !prev);
  }

  /**
   * This function triggers when transfer button is clicked
   */
  function onTransferClick() {
    customerInfo.finalTransferCOA =
      typeof totalAmount(CONSTANTS.COA) === CONSTANTS.NUMBER_STRING
        ? Number(totalAmount(CONSTANTS.COA))
        : CONSTANTS.ZERO_NUMBER;

    setAgreementDetailsCloneSetter(
      agreementDetailsClone.map((el) => {
        const addedSuspense = Number(el.suspenseAmountNew).toFixed(2);
        const epoAmount = Number(el.epoAmount).toFixed(2);
        if (agreementWithOverSusp.includes(el.agreementNumber)) {
          if (addedSuspense == epoAmount)
            (el.isOverSus = true), (el.acceptEpoFlag = CONSTANTS.ONE_NUMBER);
          else el.isOverSus = false;
        }

        delete el?.suspenseAmountNew;

        return el;
      })
    );

    const flag: FinalSuspPopups = JSON.parse(JSON.stringify(finalSuspPopups));

    flag.finalTransferGrid = false;
    setFinalSuspPopups(flag);
  }

  function onCancelClick() {
    setAgreementDetailsCloneSetter(
      agreementDetailsClone.map((el) => {
        if (agreementWithOverSusp.includes(el.agreementNumber)) {
          return {
            ...el,
            isOverSus: false,
            acceptEpoFlag: CONSTANTS.ZERO_NUMBER,
          };
        } else return el;
      })
    );

    const flag: FinalSuspPopups = JSON.parse(JSON.stringify(finalSuspPopups));

    flag.finalTransferGrid = false;
    setFinalSuspPopups(flag);
  }

  /**
   * PS_HFS_80 - PS_HFS_87
   * This function returns amount based on the argument received
   * @param value
   * @returns
   */
  function totalAmount(value?: string) {
    const agrDetails = getEligibleAgreements(agreementDetails);
    const agrDetailsClone = getEligibleAgreements(agreementDetailsClone);
    const currentSusp = agrDetails.reduce(
      (accum, item) => accum + Number(item.suspenseAmount),
      CONSTANTS.ZERO_NUMBER
    );

    const currEpo = agrDetails.reduce(
      (accum, item) => accum + Number(item.epoAmount),
      CONSTANTS.ZERO_NUMBER
    );

    const newSusp = agrDetailsClone.reduce(
      (accum, item) =>
        accum +
        (item?.suspenseAmountNew != undefined
          ? Number(item?.suspenseAmountNew)
          : Number(item?.suspenseAmount) +
            Number(item.suspenseDetails.transferSusAdd) -
            Number(item.suspenseDetails.transferSusUse)),
      CONSTANTS.ZERO_NUMBER
    );

    console.log('agreementDetailsssssss', agreementDetails);

    console.log('newSuspnewSusp', newSusp);
    return amountValue(currentSusp, newSusp, currEpo, value);
  }

  const getEligibleAgreements = (agr: AgreementDetails[]) =>
    agr.filter(
      (eachAgreement: AgreementDetails) =>
        eachAgreement.agreementType != CONSTANTS.CLUB &&
        !eachAgreement.isExchanged &&
        eachAgreement.storeNumber === storeNumber &&
        eachAgreement.agreementType == CONSTANTS.RENTAL_AGREEMENT_RTO &&
        eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
        !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(eachAgreement.nextDueDate)
    );

  function amountValue(
    currentSusp: number,
    newSusp: number,
    currEpo: number,
    value?: string
  ) {
    console.log(
      'currEpo',
      currEpo,
      'newSusp',
      newSusp,
      'currentSusp',
      currentSusp
    );
    switch (value) {
      case CONSTANTS.CURRENT_SUSP:
        return currentSusp;
      case CONSTANTS.NEW_SUSP:
        return newSusp;
      case CONSTANTS.DIFF_SUSP:
        return currEpo == newSusp
          ? CONSTANTS.ZERO_TWO_DECIMAL_NUMBER
          : currentSusp - newSusp;
      case CONSTANTS.COA:
        return currEpo == newSusp
          ? currentSusp - newSusp
          : CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
      default:
        return currEpo == newSusp
          ? currentSusp - (newSusp - (currentSusp - newSusp)) ===
            CONSTANTS.ZERO_NUMBER
            ? true
            : false
          : currentSusp - newSusp === CONSTANTS.ZERO_NUMBER
          ? false
          : true;
    }
  }

  /**
   * PS_SC_52 - PS_SC_56
   * This function binds the suspense data to the grid
   * @returns
   */
  const bindSuspenseGrid = () => {
    return (
      <>
        {agreementDetailsClone.map(
          (eachAgreement: AgreementDetails, index: number) => {
            const {
              agreementNumber,
              agreementSchedule,
              totalDueAmount,
              epoAmount,
              suspenseAmount,
              agreementId,
            } = eachAgreement;

            const agreementOld = agreementDetails.find(
              (el) => el?.agreementId == agreementId
            );

            const suspAmount: string = (
              Number(agreementOld?.suspenseAmount) +
              Number(agreementOld?.suspenseDetails?.transferSusAdd) -
              Number(agreementOld?.suspenseDetails?.transferSusUse)
            ).toFixed(2);

            const textBoxValue = eachAgreement?.suspenseAmountNew ?? suspAmount;

            return (
              eachAgreement.agreementType != CONSTANTS.CLUB &&
              !eachAgreement.isExchanged &&
              eachAgreement.storeNumber === storeNumber &&
              eachAgreement.agreementType == CONSTANTS.RENTAL_AGREEMENT_RTO &&
              eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
              !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
                eachAgreement.nextDueDate
              ) && (
                <RACTableRow
                  key={index}
                  className={payclasses.payHistbgTransparent}
                >
                  <RACTableCell>
                    <Typography variant="caption" color="primary">
                      {agreementNumber}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {' '}
                      {schedule(agreementSchedule)}{' '}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {Number(totalDueAmount).toFixed(2)}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">
                      {' '}
                      {Number(epoAmount).toFixed(2)}{' '}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography variant="caption">{suspAmount}</Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <RACTextbox
                      id={agreementId}
                      isCurrency={true}
                      className={`${classes.custDigit} ${classes.cashtxtbox}`}
                      dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                      value={textBoxValue}
                      data-testid="suspensetextbox"
                      name={'suspenseTxtbox' + index}
                      maxlength={10}
                      errorMessage={
                        eachAgreement.error ?? CONSTANTS.EMPTY_STRING
                      }
                      OnChange={(e) =>
                        transferSusupense(e, {
                          epoAmount,
                          suspenseAmount: Number(suspenseAmount).toFixed(2),
                        })
                      }
                      Onblur={(e) =>
                        transferSusupense(
                          e,
                          {
                            epoAmount,
                            suspenseAmount: Number(suspenseAmount).toFixed(2),
                          },
                          true
                        )
                      }
                    />
                  </RACTableCell>
                </RACTableRow>
              )
            );
          }
        )}
      </>
    );
  };

  function bindSuspenseHeader() {
    const classes = takePaymentStyles();
    const headerArray = CONSTANTS.SUSPENSE_POPUP_HEADER;
    return (
      <>
        {headerArray.map((val, index) => {
          return val === null ? (
            <RACTableCell key={index} className={`${classes.font12}`}>
              <span style={{ float: 'right' }}>{val}</span>
            </RACTableCell>
          ) : (
            <RACTableCell key={index} className={`${classes.font12}`}>
              {val}
            </RACTableCell>
          );
        })}
      </>
    );
  }

  /**
   * PS_SC_44 - PS_SC_51
   * Within return, it binds grid with transfer and cancel button
   */
  return (
    <Grid
      item
      data-testid="addcheckbtnmode"
      id="check-info"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="carrywaive"
      aria-hidden="true"
    >
      <Grid container spacing={2} className={classes.px3}>
        <RACTable
          className={classes.racGrid}
          renderTableHead={bindSuspenseHeader}
          renderTableContent={bindSuspenseGrid}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Grid
          className={` ${payclasses.payHistbgLightBlue} ${payclasses.payHistp3} ${payclasses.payHistmt2} ${payclasses.payHistborderRadius5}`}
        >
          <Grid container className={payclasses.payHistjustifySpaceBetween}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    Current Suspense Total:{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                  >
                    {'  ' +
                      CONSTANTS.DOLLAR +
                      Number(totalAmount(CONSTANTS.CURRENT_SUSP)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    New Suspense Total :{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                  >
                    {CONSTANTS.DOLLAR +
                      Number(totalAmount(CONSTANTS.NEW_SUSP)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    Difference :{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                  >
                    ${Number(totalAmount(CONSTANTS.DIFF_SUSP)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className={payclasses.payHistmb2}
            >
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7}`}
                  >
                    {' '}
                    Change/COA :{' '}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    className={`${payclasses.payHisttextBlue} ${payclasses.payHistfs7} ${payclasses.payHistfontBold}`}
                  >
                    {CONSTANTS.DOLLAR +
                      Number(totalAmount(CONSTANTS.COA)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
        <RACButton
          className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
          variant="outlined"
          color="primary"
          style={{
            backgroundColor: 'white',
            textTransform: 'none',
            fontWeight: 'bolder',
            paddingLeft: '14px',
            paddingRight: '14px',
            color: '#2179fe',
          }}
          onClick={() => onCancelClick()}
          data-bs-toggle="modal"
        >
          Cancel
        </RACButton>
        <RACButton
          className={`${classes.paymentSmallBtn1}`}
          color="primary"
          variant="contained"
          disabled={
            (totalAmount() as boolean) ||
            agreementDetailsClone.some((el) => el.error)
          }
          onClick={() => onTransferClick()}
          data-testid="addchecksave"
        >
          Transfer
        </RACButton>
      </Grid>
    </Grid>
  );
};
