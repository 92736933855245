/**
 * PS_RET_02
 * Import the required packages, interface and context
 */
import React, { useContext, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { getRetentionOffers, postAgreementActivity } from '../../api/user';
import { useHistory, useParams } from 'react-router-dom';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACButton,
  RACCheckBox,
  RACModalCard,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from '@rentacenter/racstrap';
import { RacExchangePopup } from './RACExchange';
import { selectedRetentionOrExchangeId } from '../interface/racExchangePopupInterface';
import { ParamType } from '../interface/commonInterface';
import {
  retentionOptionsArray,
  agreementActivityPayload,
  AgreementOffers,
  ReturnReasons,
  OfferResponse,
  PayloadMap,
  postAgreementPayload,
} from '../interface/retentionInterface';
import CONSTANTS from '../constants/constant';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AgreementContextValue } from '../interface/contextInterface';

/**
 * PS_RET_03 - PS_RET_90
 * Declare a function as RetentionComponent()
 * @param props - agreement id and agreement number
 * @returns
 */
export default function RetentionComponent(
  props: selectedRetentionOrExchangeId
) {
  /**
   * PS_RET_04 - PS_RET_05
   * Declaring variables and destructing the agreement context
   */
  const history = useHistory();
  const classes = takePaymentStyles();
  const { customerId } = useParams<ParamType>();
  const agreementId = props.agreementDetails.agreementId;
  const agreementNumber = props.agreementDetails.agreementNumber;

  const { featureFlags, modalCardEnable, setModalCardEnable } =
    useContext<AgreementContextValue>(agreementContext);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [availableOffers, setAvailableOffers] = useState<string[]>();
  const [selectedOffer, setSelectedOffer] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [offerResponse, setOfferResponse] = useState<OfferResponse>();
  const [retentionOptions, setRetentionOptions] = useState<
    retentionOptionsArray[]
  >([]);
  const [selectedRetentionOption, setSelectedRetentionOption] =
    useState<retentionOptionsArray | null>();

  /**
   * PS_RET_08 - PS_RET_17
   * This makes api call to retrive retention offers against the agreement
   * and sets the offers to available offers state variable and response to offer response
   */
  const { isLoading, isFetching } = useQuery({
    queryKey: [CONSTANTS.API_KEY_GET_RETENTION_OFFERS, agreementNumber],
    queryFn: () =>
      getRetentionOffers({
        agreementNumbers: [String(agreementNumber)],
      }),
    onSuccess: (retentionOfferResponse) => {
      const offerLabels: string[] = CONSTANTS.RETENTION_OFFERS_ARRAY;
      retentionOfferResponse?.data?.returnReasons?.map((el: ReturnReasons) => {
        if (!offerLabels.includes(el.reason)) offerLabels.push(el.reason);
      });

      if (
        retentionOfferResponse?.data &&
        retentionOfferResponse?.data?.agreementOffers[0]
          ?.exchangeOfferDescription &&
        featureFlags?.RACExchange &&
        !offerLabels.includes(CONSTANTS.RAC_EXCHANGE)
      )
        offerLabels.push(CONSTANTS.RAC_EXCHANGE);

      setAvailableOffers(offerLabels);
      if (retentionOfferResponse?.data)
        setOfferResponse(retentionOfferResponse?.data);
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: postAgreementPayload) =>
      postAgreementActivity(payload),
  });

  /**
   * PS_RET_18 - PS_RET_36
   * This function sets the retention options based on selected offer
   * @param el - selected offer
   */
  const optionBasedOnChoosenOffer = (el: string) => {
    const choosenOffer = el;
    const retentionOptionsArray: retentionOptionsArray[] = [];
    if (!offerResponse) {
      retentionOptionsArray.push({
        displayLabel: CONSTANTS.NO_RETENTION_OFFER_OPTION_AVAILABLE,
      });
      setRetentionOptions(retentionOptionsArray);
      return;
    }

    const filteredResponse = offerResponse?.returnReasons?.filter(
      (el: ReturnReasons) => el.reason == choosenOffer
    );

    const agreementData: AgreementOffers[] =
      getSortedAgreementData(offerResponse);

    addExchangeOffer(agreementData, retentionOptionsArray);

    if (CONSTANTS.PRODUCT_SERVICE_ARRAY.includes(choosenOffer)) {
      addProductServiceOptions(retentionOptionsArray);
    } else if (choosenOffer == CONSTANTS.NO_LONGER_NEEDS) {
      addNoLongerNeedsOptions(retentionOptionsArray);
    } else if (choosenOffer == CONSTANTS.MOVING) {
      addMovingOptions(retentionOptionsArray);
    } else if (choosenOffer == CONSTANTS.RAC_EXCHANGE) {
      addRACExchangeOptions(agreementData, retentionOptionsArray);
    } else {
      addFilteredResponseOptions(
        filteredResponse,
        agreementData,
        retentionOptionsArray
      );
    }

    setRetentionOptions(retentionOptionsArray);
  };

  const getSortedAgreementData = (offerResponse: OfferResponse) => {
    const offers: AgreementOffers[] = offerResponse?.agreementOffers.map(
      (offer: AgreementOffers) => ({
        ...offer,
        updateDateTime: new Date(offer.updateDateTime),
      })
    );
    return offers?.sort(
      (a: AgreementOffers, b: AgreementOffers) =>
        +new Date(b.updateDateTime) - +new Date(a.updateDateTime)
    );
  };

  const addExchangeOffer = (
    agreementData: AgreementOffers[],
    retentionOptionsArray: retentionOptionsArray[]
  ) => {
    if (
      agreementData[0]?.exchangeOfferDescription &&
      !featureFlags.RACExchange
    ) {
      retentionOptionsArray.push({
        displayLabel: agreementData[0]?.exchangeOfferDescription,
        offerValue: {
          redirectionPage: CONSTANTS.INVENTORY,
          functionalityInPage: CONSTANTS.AGREXC,
          description: agreementData[0].exchangeOfferDescription,
          agreementExchangeAmount: agreementData[0]?.exchangeAmount,
        },
      });
    }
  };

  const addProductServiceOptions = (
    retentionOptionsArray: retentionOptionsArray[]
  ) => {
    retentionOptionsArray.push(
      {
        displayLabel: CONSTANTS.REPAIR_SWITCHOUT_PRODUCT,
        offerValue: {
          redirectionPage: CONSTANTS.AGREEMENT,
          functionalityInPage: CONSTANTS.SW,
        },
      },
      {
        displayLabel: CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER,
      }
    );
  };

  const addNoLongerNeedsOptions = (
    retentionOptionsArray: retentionOptionsArray[]
  ) => {
    retentionOptionsArray.push(
      {
        displayLabel: CONSTANTS.EXPLAIN_LIFETIME_REINSTATEMENT_TO_THE_CUSTOMER,
        offerValue: {
          redirectionPage: CONSTANTS.AGREEMENT,
          functionalityInPage: CONSTANTS.RETENTION_R,
        },
      },
      {
        displayLabel: CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER,
      }
    );
  };

  const addMovingOptions = (retentionOptionsArray: retentionOptionsArray[]) => {
    retentionOptionsArray.push(
      {
        displayLabel: CONSTANTS.GIVE_CUSTOMERS_THEIR_LOCAL_RAC_STORE_INFO,
        offerValue: {
          redirectionPage: CONSTANTS.STORE_MANAGEMENT,
          functionalityInPage: CONSTANTS.MOVE,
        },
      },
      {
        displayLabel: CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER,
      }
    );
  };

  const addRACExchangeOptions = (
    agreementData: AgreementOffers[],
    retentionOptionsArray: retentionOptionsArray[]
  ) => {
    retentionOptionsArray.push(
      {
        displayLabel: agreementData[0]?.exchangeOfferDescription,
        offerValue: {
          redirectionPage: CONSTANTS.INVENTORY,
          functionalityInPage: CONSTANTS.AGMXNGS,
          description: CONSTANTS.AGREEMENT_EXCHANGE_SELECTED,
        },
      },
      {
        displayLabel: CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER,
      }
    );
  };

  const addFilteredResponseOptions = (
    filteredResponse: ReturnReasons[],
    agreementData: AgreementOffers[],
    retentionOptionsArray: retentionOptionsArray[]
  ) => {
    filteredResponse.forEach((el) => {
      if (el.epoEligible == CONSTANTS.YES) {
        retentionOptionsArray.push({
          displayLabel: agreementData[0].epoOfferDescription,
          offerValue: {
            redirectionPage: CONSTANTS.PAYMENT,
            functionalityInPage: CONSTANTS.EPO,
            currentEpo: agreementData[0].currentEpo,
            epoDiscount: agreementData[0].epoDiscount,
            newEpo: agreementData[0].newEpo,
          },
        });
      }
      if (el.rateReductionEligible == CONSTANTS.YES) {
        retentionOptionsArray.push({
          displayLabel: agreementData[0].rateOfferDescription,
          offerValue: {
            redirectionPage: CONSTANTS.AGREEMENT,
            functionalityInPage: CONSTANTS.RR,
            currentRate: agreementData[0].currentRate,
            rateReduction: agreementData[0].rateReduction,
            newRate: agreementData[0].newRate,
          },
        });
      }
      if (el.termReductionEligible == CONSTANTS.YES) {
        retentionOptionsArray.push({
          displayLabel: agreementData[0].termOfferReduction,
          offerValue: {
            redirectionPage: CONSTANTS.AGREEMENT,
            functionalityInPage: CONSTANTS.TR,
            currentRemainingTerm: agreementData[0].currentRemainingTerm,
            termReduction: agreementData[0].termReduction,
            newRemainingTerm: agreementData[0].newRemainingTerm,
          },
        });
      }
      if (el.extensionEligible == CONSTANTS.YES) {
        retentionOptionsArray.push({
          displayLabel: agreementData[0].extensionOfferDescription,
          offerValue: {
            redirectionPage: CONSTANTS.PAYMENT,
            functionalityInPage: CONSTANTS.FREETIME,
            extension: agreementData[0].extension,
          },
        });
      }
    });

    if (retentionOptionsArray.length) {
      retentionOptionsArray.push({
        displayLabel: CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER,
      });
    } else {
      retentionOptionsArray.push({
        displayLabel: CONSTANTS.NO_RETENTION_OFFER_OPTION_AVAILABLE,
      });
    }
  };

  /**
   * PS_RET_37 - PS_RET_64
   * This function makes api call to post the activity performed
   */
  const getAgreementActivityPayload = (
    selectedRetentionOption: retentionOptionsArray,
    agreementId: string,
    date: string
  ): agreementActivityPayload | null => {
    const payloadMap: PayloadMap = {
      [`${CONSTANTS.PAYMENT}_${CONSTANTS.EPO}`]: {
        activityDescription: CONSTANTS.AGREEMENT_EPO_SELECTED,
        agreementActivityType: CONSTANTS.AEPOS,
      },
      [`${CONSTANTS.PAYMENT}_${CONSTANTS.FREETIME}`]: {
        activityDescription: CONSTANTS.AGREEMENT_EXTENSION_SELECTED,
        agreementActivityType: CONSTANTS.AEXTNS,
      },
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.RR}`]: {
        activityDescription: CONSTANTS.AGREEMENT_RATE_ADJUSTMENT_SELECTED,
        agreementActivityType: CONSTANTS.ARAS,
      },
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.TR}`]: {
        activityDescription: CONSTANTS.AGREEMENT_TERM_ADJUSTMENT_SELECTED,
        agreementActivityType: CONSTANTS.ATAS,
      },
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.SW}`]: {
        activityDescription: CONSTANTS.AGR_RETENTION_SWITCHOUT_SELECTED,
        agreementActivityType: CONSTANTS.AGMRETSWS,
      },
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.RETENTION_S}`]: {
        activityDescription: CONSTANTS.AGR_RETENTION_SERVICE_SELECTED,
        agreementActivityType: CONSTANTS.AGMRETSS,
      },
      [`${CONSTANTS.STORE_MANAGEMENT}_${CONSTANTS.MOVE}`]: {
        activityDescription: CONSTANTS.MOVING_SELECTED,
        agreementActivityType: CONSTANTS.MS,
      },
      [CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER]: {
        activityDescription: CONSTANTS.AGR_RETENTION_NO_OFFER_SELECTED,
        agreementActivityType: CONSTANTS.AGMRETNONE,
      },
      [CONSTANTS.NO_RETENTION_OFFER_OPTION_AVAILABLE]: {
        activityDescription: CONSTANTS.AGR_RETENTION_NO_OFFER_AVAILABLE,
        agreementActivityType: CONSTANTS.RETNOAVAIL,
      },
      [CONSTANTS.INVENTORY]: {
        activityDescription: CONSTANTS.AGREEMENT_EXCHANGE_SELECTED,
        agreementActivityType: CONSTANTS.AGMXNGS,
      },
      [CONSTANTS.EXPLAIN_LIFETIME_REINSTATEMENT_TO_THE_CUSTOMER]: {
        activityDescription: CONSTANTS.NO_LONGER_NEEDS_SELECTED,
        agreementActivityType: CONSTANTS.NLNS,
      },
    };

    let key =
      selectedRetentionOption?.offerValue?.redirectionPage ??
      CONSTANTS.EMPTY_STRING;
    if (key === CONSTANTS.PAYMENT || key === CONSTANTS.AGREEMENT) {
      key += `_${selectedRetentionOption?.offerValue?.functionalityInPage}`;
    } else if (
      selectedRetentionOption?.displayLabel &&
      key != CONSTANTS.INVENTORY
    ) {
      key = selectedRetentionOption.displayLabel;
    }

    const payload = payloadMap[key];
    if (payload) {
      return {
        activityDate: date,
        agreementId: agreementId,
        ...payload,
      };
    }

    return CONSTANTS.NULL;
  };

  const postAgreementActivityDetails = async () => {
    setSaveLoader(true);
    const today = new Date();
    const date = today.toISOString().split(CONSTANTS.SPLIT_WITH_T)[0];

    const payload = getAgreementActivityPayload(
      selectedRetentionOption ?? ({} as retentionOptionsArray),
      agreementId,
      date
    );

    if (payload) {
      const apiPayload: postAgreementPayload = {
        agreementActivities: [payload],
      };
      await mutation.mutateAsync(apiPayload);
    }
    handleRedirection();
  };

  /**
   * This function redirects the coWorker based on the option selected
   * closes the loader and retention popup
   */
  const handleRedirection = () => {
    const redirectionMap = {
      [`${CONSTANTS.PAYMENT}_${CONSTANTS.EPO}`]: () => {
        const epoDiscountAmount =
          selectedRetentionOption?.offerValue?.epoDiscount
            ?.trim()
            .replace(/\$/g, '');
        return {
          pathname: `/payment1/paymentinformation/${customerId}/0`,
          search: `?CRTYPE=EPO&SRC=PAYMENT`,
          state: {
            retentionData: {
              newEpo: selectedRetentionOption?.offerValue?.newEpo,
              epoDiscount: epoDiscountAmount,
              currentEpo: selectedRetentionOption?.offerValue?.currentEpo,
              agreementNumber: agreementNumber,
              agreementId: agreementId,
              retentionEPOMessage: selectedRetentionOption?.displayLabel,
            },
          },
        };
      },
      [`${CONSTANTS.PAYMENT}_${CONSTANTS.FREETIME}`]: () => ({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=FREETIME&SRC=PAYMENT`,
        state: {
          retentionData: {
            extentionDays: selectedRetentionOption?.offerValue?.extension,
            agreementNumber: agreementNumber,
            agreementId: agreementId,
          },
        },
      }),
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.RR}`]: () =>
        getAgreementRedirection(CONSTANTS.RR),
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.TR}`]: () =>
        getAgreementRedirection(CONSTANTS.TR),
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.SW}`]: () =>
        getAgreementRedirection(CONSTANTS.SW),
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.RETENTION_R}`]: () =>
        getAgreementRedirection(CONSTANTS.RETENTION_R),
      [`${CONSTANTS.AGREEMENT}_${CONSTANTS.RETENTION_S}`]: () =>
        getAgreementRedirection(CONSTANTS.RETENTION_S),
      [`${CONSTANTS.STORE_MANAGEMENT}_${CONSTANTS.MOVE}`]: () => ({
        pathname: `/storemgmt/lookupstore`,
      }),
      [CONSTANTS.INVENTORY]: () => {
        if (featureFlags?.RACExchange) {
          return {
            pathname: `/agreement/rental/itemsearch/${customerId}`,
            search: `?isExchange=1&agreementId=${agreementId}`,
          };
        } else {
          setModalCardEnable({
            ...modalCardEnable,
            racExchangeComponent: true,
          });
          return CONSTANTS.NULL;
        }
      },
    };

    const getAgreementRedirection = (type: string) => ({
      pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      search: `?source=AMRETENTION&type=${type}`,
      state: {
        retentionData:
          type === CONSTANTS.RR
            ? { retentionData: offerResponse }
            : offerResponse,
      },
    });

    let key = `${selectedRetentionOption?.offerValue?.redirectionPage}`;
    if (key != CONSTANTS.INVENTORY)
      key += `_${selectedRetentionOption?.offerValue?.functionalityInPage}`;
    const redirectionFunction = redirectionMap[key];

    if (redirectionFunction) {
      const redirectionData = redirectionFunction();
      if (redirectionData) {
        history.push(redirectionData);
        if (key.startsWith(CONSTANTS.PAYMENT)) {
          window.location.reload();
        }
      }
    }

    setSaveLoader(false);
    setModalCardEnable({ ...modalCardEnable, retentionPopup: false });
  };

  /**
   * PS_RET_82 - PS_RET_87
   * This function makes api call and close the popup
   */
  const closeRetentionPopup = async () => {
    const today = new Date();
    const date = today.toISOString().split(CONSTANTS.SPLIT_WITH_T)[0];

    const offerConfig = {
      [CONSTANTS.TOTAL_RTO_TERM_TOO_HIGH]: {
        prefix: CONSTANTS.TRTO_HIGH_CANCELED,
        type: CONSTANTS.CANSCRTRT,
        cancelType: CONSTANTS.CANCRTRTO,
      },
      [CONSTANTS.SAC_TOO_HIGH]: {
        prefix: CONSTANTS.SAC_HIGH_CANCELED,
        type: CONSTANTS.CANSCRSACT,
        cancelType: CONSTANTS.CANCRSACTH,
      },
      [CONSTANTS.EPO_TOO_HIGH]: {
        prefix: CONSTANTS.EPO_HIGH_CANCELED,
        type: CONSTANTS.CANSCREPOT,
        cancelType: CONSTANTS.CANCREPOTH,
      },
      [CONSTANTS.RENEWAL_RATE_TOO_HIGH]: {
        prefix: CONSTANTS.RATE_HIGH_CANCELED,
        type: CONSTANTS.CANSCRRTH,
        cancelType: CONSTANTS.CANCRRTH,
      },
      [CONSTANTS.CANNOT_MAKE_CURRENT_PAYMENT]: {
        prefix: CONSTANTS.MAKE_PAYMENT_CANCELED,
        type: CONSTANTS.CANSCRCMCP,
        cancelType: CONSTANTS.CANCCRCMCP,
      },
      [CONSTANTS.SERVICE]: {
        prefix: CONSTANTS.SERVICE,
        type: CONSTANTS.CANSCRSERV,
        cancelType: CONSTANTS.CANCRSERV,
      },
      [CONSTANTS.PRODUCT]: {
        prefix: CONSTANTS.PRODUCT,
        type: CONSTANTS.CANSCRPROD,
        cancelType: CONSTANTS.CANCRPROD,
      },
      [CONSTANTS.MOVING]: {
        prefix: CONSTANTS.MOVING,
        type: CONSTANTS.CANSCRMOVI,
        cancelType: CONSTANTS.CANCRMOVI,
      },
      [CONSTANTS.NO_LONGER_NEEDS]: {
        prefix: CONSTANTS.NO_LONGER_NEEDS,
        type: CONSTANTS.CANSCRNLN,
        cancelType: CONSTANTS.CANCRNLN,
      },
      [CONSTANTS.RAC_EXCHANGE]: {
        prefix: CONSTANTS.NO_LONGER_NEEDS,
        type: CONSTANTS.AGMXNGCAN,
        exchangeType: CONSTANTS.CANSXNG,
        cancelType: CONSTANTS.CANXNG,
      },
    };

    const config = offerConfig[selectedOffer];

    const createActivity = (description: string, type: string) => ({
      activityDate: date,
      agreementId: agreementId,
      activityDescription: description,
      agreementActivityType: type,
    });

    const payloadAgreementActivities: agreementActivityPayload[] = [];

    if (config) {
      retentionOptions.forEach((el: retentionOptionsArray) => {
        if (
          el.displayLabel !== CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER &&
          el.displayLabel !== CONSTANTS.NO_RETENTION_OFFER_OPTION_AVAILABLE
        ) {
          payloadAgreementActivities.push(
            createActivity(
              `${config.prefix}: ${CONSTANTS.SEL} ${el.displayLabel}`,
              config.type
            )
          );
        }
      });

      const isCustomerNotAcceptingOffer =
        selectedRetentionOption?.displayLabel ===
        CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER;

      payloadAgreementActivities.push(
        createActivity(
          CONSTANTS[
            `${config.prefix}: ${
              isCustomerNotAcceptingOffer ? CONSTANTS.SEL : CONSTANTS.NOT_SEL
            } ${CONSTANTS.CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER}`
          ],
          isCustomerNotAcceptingOffer
            ? config.exchangeType || config.type
            : config.cancelType
        )
      );
    }
    if (payloadAgreementActivities.length) {
      const apiPayload: postAgreementPayload = {
        agreementActivities: payloadAgreementActivities,
      };

      mutation.mutateAsync(apiPayload);
    }
    setModalCardEnable({ ...modalCardEnable, retentionPopup: false });
  };

  /**
   * PS_RET_73 - PS_RET_81
   * This function holds html for retention
   * @returns
   */
  const retentionPopup = () => {
    return (
      <Grid container className={classes.mt2}>
        <Typography variant="h6" className={`${classes.title2} ${classes.mb1}`}>
          Please select why the customer wishes to return to see Customer
          Retention Offer
        </Typography>
        <Grid container spacing={2}>
          {availableOffers?.map((el: string, index: number) => {
            return (
              <Grid item sm={6} md={3} className={`${classes.mb2}`} key={index}>
                <Card
                  className={
                    el == selectedOffer
                      ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                      : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                  }
                  onClick={() => {
                    setSelectedOffer(el);
                    setSelectedRetentionOption(CONSTANTS.NULL);
                    optionBasedOnChoosenOffer(el);
                  }}
                >
                  <CardContent>
                    <Grid container className={classes.justifyLabel}>
                      <Typography
                        className={
                          el == selectedOffer
                            ? classes.paySchedulePeriodHighlight
                            : classes.paySchedulePeriodNotHighlight
                        }
                        variant="body1"
                      >
                        {el}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {retentionOptions?.length > CONSTANTS.ZERO_NUMBER &&
          retentionOptionCheckboxBind()}
      </Grid>
    );
  };

  const retentionOptionCheckboxBind = () => (
    <Grid container className={`${classes.mb2}`}>
      <Card
        className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
      >
        <Grid
          item
          className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
        >
          <Grid container className={`${classes.px2} ${classes.h65}`}>
            <Grid
              className={`${classes.w100} ${classes.paymentAlignCenter1} ${classes.floatLeft}`}
            >
              <Grid
                item
                className={`${classes.raccollg6_1}  ${classes.floatLeft}`}
              >
                <Typography
                  component="span"
                  className={`${classes.titletxtstyle1} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                >
                  Retention Option
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={`${classes.px2}`}>
            {retentionOptions?.map(
              (el: retentionOptionsArray, index: number) => {
                return (
                  <Grid
                    className={`${classes.w100} ${classes.paymentAlignCenter1} ${classes.floatLeft}`}
                    key={index}
                  >
                    <RACCheckBox
                      size="medium"
                      value={el.displayLabel}
                      key={index}
                      onClick={() => {
                        setSelectedRetentionOption((prev) =>
                          prev?.displayLabel != el.displayLabel
                            ? el
                            : CONSTANTS.NULL
                        );
                      }}
                      checked={
                        selectedRetentionOption?.displayLabel == el.displayLabel
                      }
                      label={el.displayLabel}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
        >
          <RACButton
            className={`${classes.floatRight} ${classes.ms2}`}
            color="primary"
            variant="contained"
            disabled={selectedRetentionOption ? false : true}
            onClick={() => {
              if (
                selectedRetentionOption?.displayLabel ==
                CONSTANTS.EXPLAIN_LIFETIME_REINSTATEMENT_TO_THE_CUSTOMER
              )
                setOpenReinstateAlert(true);
              else postAgreementActivityDetails();
            }}
          >
            Record
          </RACButton>
          <RACButton
            className={`${classes.floatRight} ${classes.ms2}`}
            color="primary"
            variant="outlined"
            onClick={() => closeRetentionPopup()}
          >
            Cancel
          </RACButton>
        </Grid>
      </Card>
    </Grid>
  );
  /**
   * This function holds the html for the fetching offers popup
   */
  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  /**
   * PS_RET_69 - PS_RET_72
   * This function holds html for save loader popup
   * @returns
   */
  const saveLoaderPopup = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Saving Information
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  /**
   * PS_RET_65 - PS_RET_68
   * This function holds html for the popup
   * @returns
   */
  const buildReinstateAlert = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            At any time you can contact your store to schedule a return of your
            product or return merchandise in person and pause your payments.
            When you are ready, you can come back, reinstate your agreement to
            get the same or comparable item, and pick up your payments right
            where you left off.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              postAgreementActivityDetails();
            }}
          >
            Return
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setOpenReinstateAlert(false)}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_RET_88 - PS_RET_90
   * Within return, all the modalcards have been declared
   */
  return (
    <>
      {isFetching || isLoading ? (
        <RACModalCard
          isOpen={isFetching || isLoading}
          maxWidth="xs"
          borderRadius={'25px'}
        >
          {offerLoadingRenderer()}
        </RACModalCard>
      ) : (
        <RACModalCard
          isOpen={
            modalCardEnable.retentionPopup && !openReinstateAlert && !saveLoader
          }
          maxWidth="xl"
          title="Customer Retention"
          borderRadius={'25px'}
          closeIcon={true}
          onClose={() =>
            setModalCardEnable({ ...modalCardEnable, retentionPopup: false })
          }
        >
          {retentionPopup()}
        </RACModalCard>
      )}

      {saveLoader && (
        <RACModalCard isOpen={saveLoader} maxWidth="xs" borderRadius={'25px'}>
          {saveLoaderPopup()}
        </RACModalCard>
      )}

      {openReinstateAlert && (
        <RACModalCard
          isOpen={openReinstateAlert}
          maxWidth="sm"
          borderRadius={'25px'}
        >
          {buildReinstateAlert()}
        </RACModalCard>
      )}

      {modalCardEnable.racExchangeComponent && (
        <RacExchangePopup
          agreementDetails={{
            agreementId: agreementId,
            agreementNumber: agreementNumber,
          }}
        />
      )}
    </>
  );
}
