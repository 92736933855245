/*eslint-disable*/
import { useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';

import moment from 'moment';
import { AgreementDetails, Customer } from '../interface/paymentInfoInterface';
import { calculateTotalDueAmount } from '../utils/calculateTotalDueAmount';
import CONSTANTS from '../constants/constant';
import { useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';

interface Transaction {
  type: string;
  value: string | null;
}

interface Agreement {
  agreementId?: string;
  nextDueDate: string | null;
  currentDueDate: string;
  amount: string;
  transactions?: Transaction[];
}

interface ClubAgreement {
  clubId: string;
  nextDueDate: string;
  currentDueDate: string;
  amount: string;
  transactions?: Transaction[];
}

// Define other interfaces as needed...

export const continueAMClicked = (agreementDetails:AgreementDetails[], location, customerInfo:Customer, history) => {
  const filteredNonClubData = agreementDetails.filter(
    (obj) => obj.agreementType !== 'Club' && obj.selected == true
  );
  const filteredClubData = agreementDetails.filter(
    (obj) => obj.agreementType === 'Club' && obj.selected == true
  );

  const agreementPayload: Agreement[] = [];
  const clubPayLoad: ClubAgreement[] = [];

  const formatAmount = (amount, suspenseAdd, suspenseSub) => {
    const total = Number(amount) + Number(suspenseAdd) - Number(suspenseSub);
    return total < 0 ? '0.00' : Number(total).toFixed(2);
  };

  const addTransactionToAgreement = (agreementId, transaction, type) => {
    if(type==CONSTANTS.AGR){
    const index = agreementPayload.findIndex(
      (el) => Number(el.agreementId) === Number(agreementId)
    );
    if (index >= 0) {
      agreementPayload[index]?.transactions?.push(transaction);
    } else {
      agreementPayload.push({
        agreementId,
        ...transaction,
      });
    }
  }else if(type==CONSTANTS.CLUB){
    const index = clubPayLoad.findIndex(
      (el) => Number(el.clubId) === Number(agreementId)
    );
    if (index >= 0) {
      clubPayLoad[index]?.transactions?.push(transaction);
    } else {
      clubPayLoad.push({
        clubId: agreementId,
        ...transaction,
      });
    }
  }
  };

  const processNonClubData = (obj) => {
    const commonProps: Agreement = {
      nextDueDate:
        obj.nextDueDate !== 'EPO' &&
        obj.nextDueDate !== 'PIF' &&
        obj.nextDueDate !== 'SAC'
          ? String(moment(obj.nextDueDate).format('YYYY-MM-DD'))
          : null,
      currentDueDate: String(moment(obj.currentDueDate).format('YYYY-MM-DD')),
      amount: formatAmount(
        obj.totalDueAmount,
        obj.suspenseDetails.suspAdd,
        obj.suspenseDetails.suspUse
      ),
      transactions: [],
    };

    if (
      obj.isAdjustDuedateEdit &&
      obj.nextDueDate !== 'SAC' &&
      obj.nextDueDate !== 'EPO' &&
      obj.nextDueDate !== 'PIF'
    ) {
      commonProps?.transactions?.push({
        type: 'DDA',
        value: moment(obj.nextDueDate).format('YYYY-MM-DD'),
      });
    }

    if (obj.editSchedule) {
      commonProps?.transactions?.push({
        type: 'ES',
        value: obj.editSchedule,
      });
    }

    if (obj.freeTime) {
      commonProps?.transactions?.push(
        { type: 'FTT', value: obj.freeTime.freeTimeType },
        {
          type: 'WOS',
          value: obj.freeTime.isOptServiceWaived ? 'True' : 'False',
        },
        { type: 'DDA', value: moment(obj.nextDueDate).format('YYYY-MM-DD') }
      );
    }

    if (
      obj.acceptEpoFlag === 1 ||
      (obj.dueDateModified && obj.nextDueDate === 'SAC') ||
      obj.nextDueDate === 'EPO'
    ) {
      const editedEPOAmount = obj.actualEpo - obj?.isAcceptEPODiscounts?.epoDiscount || '0.00';
      commonProps?.transactions?.push(
        { type: 'AEF', value: 'True' },
        { type: 'AEPDA', value: Number(obj.pastDueRent)?.toFixed(2) || '0.00' },
        { type: 'AEA', value: Number(editedEPOAmount).toFixed(2) }
      );
    }

    if (obj.suspenseDetails.suspAdd > 0) {
      commonProps?.transactions?.push({
        type: 'AS',
        value: String(obj.suspenseDetails.suspAdd),
      });
    }

    if (obj.suspenseDetails.suspUse > 0) {
      commonProps?.transactions?.push({
        type: 'US',
        value: String(obj.suspenseDetails.suspUse),
      });
    }

    if (obj?.carriedLateFee > 0) {
      commonProps?.transactions?.push({
        type: 'LFC',
        value: Number(obj?.carriedLateFee)?.toFixed(2),
      });
    }

    if (obj?.waivedLateFee > 0) {
      commonProps?.transactions?.push({
        type: 'LFW',
        value: Number(obj?.waivedLateFee)?.toFixed(2),
      });
    }
    
    if(obj?.isPolicyActionPerformed == CONSTANTS.STATUS_YES && obj?.coverageStatus != CONSTANTS.STATUS_YES)
    {
      commonProps?.transactions?.push({
        type: 'AOS',
        value: 'Policy',
      }); 
    }
    
    if(obj?.isPolicyActionPerformed == CONSTANTS.STATUS_NO && obj?.coverageStatus != CONSTANTS.STATUS_NO){
      commonProps?.transactions?.push({
        type: 'TOS',
        value: 'Policy',
      });
    }
    
    if(obj?.isLDWActionPerformed == CONSTANTS.STATUS_YES && obj?.coverageStatus != CONSTANTS.STATUS_YES)
      {
        commonProps?.transactions?.push({
          type: 'AOS',
          value: 'LDW',
        }); 
      }
      
      if(obj?.isLDWActionPerformed == CONSTANTS.STATUS_NO && obj?.coverageStatus != CONSTANTS.STATUS_NO){
        commonProps?.transactions?.push({
          type: 'TOS',
          value: 'LDW',
        });
      }

    if(obj?.isPolicyActionPerformed == CONSTANTS.STATUS_YES && obj?.coverageStatus != CONSTANTS.STATUS_YES)
    {
      commonProps?.transactions?.push({
        type: 'AOS',
        value: 'Policy',
      }); 
    }

    if(obj?.isPolicyActionPerformed == CONSTANTS.STATUS_NO && obj?.coverageStatus != CONSTANTS.STATUS_NO){
      commonProps?.transactions?.push({
        type: 'TOS',
        value: 'Policy',
      });
    }

    if(obj?.isLDWActionPerformed == CONSTANTS.STATUS_YES && obj?.coverageStatus != CONSTANTS.STATUS_YES)
      {
        commonProps?.transactions?.push({
          type: 'AOS',
          value: 'LDW',
        }); 
      }

      if(obj?.isLDWActionPerformed == CONSTANTS.STATUS_NO && obj?.coverageStatus != CONSTANTS.STATUS_NO){
        commonProps?.transactions?.push({
          type: 'TOS',
          value: 'LDW',
        });
      }

    // Add to agreementPayload
    addTransactionToAgreement(obj.agreementId, commonProps, CONSTANTS.AGR);
  };

  filteredNonClubData.forEach(processNonClubData);

  const processClubData = (obj) => {
    const commonProps: Agreement = {
      nextDueDate: String(moment(obj.nextDueDate).format('YYYY-MM-DD')),
      currentDueDate: String(moment(obj.currentDueDate).format('YYYY-MM-DD')),
      amount: Number(
        Number(obj.totalDueAmount) < 0 ? '0.00' : Number(obj.totalDueAmount)
      )?.toFixed(2),
      transactions: [],
    };

    if (obj.isAdjustDuedateEdit) {
      commonProps?.transactions?.push({
        type: 'DDA',
        value: moment(obj.nextDueDate).format('YYYY-MM-DD'),
      });
    }

    if (obj.isEditScheduleEdited) {
      commonProps?.transactions?.push({
        type: 'ES',
        value: obj.editSchedule,
      });
    }

    if (obj.deactivateClubFlag) {
      commonProps?.transactions?.push({
        type: 'DC',
        value: 'True',
      });
    }

    // Add to clubPayLoad
    addTransactionToAgreement(obj.agreementId, commonProps, CONSTANTS.CLUB);
  };

  filteredClubData.forEach(processClubData);

  const nonAdjustedAgreements = filteredNonClubData
    ?.filter(
      (item1) =>
        !agreementPayload.some(
          (item2) => item2.agreementId === item1.agreementId
        )
    )
    .map((el) => ({
      agreementId: el.agreementId,
      nextDueDate:
        el.nextDueDate !== 'EPO' &&
        el.nextDueDate !== 'PIF' &&
        el.nextDueDate !== 'SAC'
          ? String(moment(el.nextDueDate).format('YYYY-MM-DD'))
          : null,
      currentDueDate: String(moment(el?.currentDueDate).format('YYYY-MM-DD')),
      amount: Number(el.totalDueAmount)?.toFixed(2),
      // transactions: [], // Add the transactions property
    }));

  const updatedAgreementPayload = agreementPayload.concat(
    nonAdjustedAgreements
  );
  const updatedClubPayload = clubPayLoad.concat(
    filteredClubData
      .filter(
        (item1) =>
          !clubPayLoad.some((item2) => item2.clubId === item1.agreementId)
      )
      .map((el) => ({
        clubId: String(el.agreementId),
        nextDueDate: String(moment(el.nextDueDate).format('YYYY-MM-DD')),
        currentDueDate: String(moment(el.currentDueDate).format('YYYY-MM-DD')),
        amount: Number(el.totalDueAmount).toFixed(2),
      }))
  );

  let selectedAgreement = agreementDetails.filter((obj) => obj.selected == true);
  const totalAmountOfAgreements: string[] = [];
  for (let i = 0; i <= selectedAgreement.length - 1; i++) {
    const amount: string | number = calculateTotalDueAmount(selectedAgreement[i]);

    totalAmountOfAgreements.push(amount.toString());
  }

  const filteredArray = totalAmountOfAgreements.filter(
    (item: string) =>
      !item.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
      !item.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  );

  const totalAmount=filteredArray.reduce((acc: number, item: string) => acc + Number(item), 0);

  console.log("commitmentDetails",updatedClubPayload,updatedAgreementPayload)

  history.push({
    pathname: `/am/customer/${customerInfo.customerId}`,
    state: {
      commitmentDetails: {
        commitmentType: location?.state?.commitmentData?.commitmentType,
        commitmentDate: location?.state?.commitmentData?.commitmentDate,
        commitmentTime: location?.state?.commitmentData?.commitmentTime,
        notes: location?.state?.commitmentData?.commitmentNotes,
        amount: String(
          Math.max(0, totalAmount + Number(customerInfo?.amountUsed?.carryRent) )?.toFixed(2)
        ),
        agreements: updatedAgreementPayload.length > 0 ? updatedAgreementPayload : undefined,
        club: updatedClubPayload.length > 0 ? updatedClubPayload[0] : undefined,
      },
    },
  });
};
