/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_ES_2 - PS_ES_3
 * Import the required packages, interface and context
 */
import React, { useState, useEffect, useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACTableCell,
  RACTable,
  RACTableRow,
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  Typography,
} from '@rentacenter/racstrap';
import {
  dateFormat,
  dueDateFormat,
  schedule,
} from '../utils/scheduleAndDateFormat';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { PayHistory } from '../../stylesJS/PayHistory';
import {
  adjustDueDateCalciPayloadFormation,
  commonModulePayloadFormation,
} from '../utils/payloadFormations';
import AdjustDueDatecalci from './existingFunctions/AdjustDueDateCalci';
import { useParams } from 'react-router-dom';
import calculationModulePayloadFormation from './existingFunctions/commonModulePayloadFormation';
import { calculatePaymentModule } from '../../api/user';
import { DropDownArray, ParamType } from '../interface/commonInterface';
import CONSTANTS from '../constants/constant';
import {
  AgreementDetails,
  PaymentScheduleReferences,
} from '../interface/paymentInfoInterface';
import {
  EditScheduleProps,
  GridData,
} from '../interface/editScheduleInterface';
import {
  AgreementPaymentDetails,
  ClubPaymentDetails,
} from '../interface/calculatePaymentResponse';
import {
  AdjustDueDateReq,
  AdjustDueResponse,
} from '../interface/adjustDueDateCalciInterface';

/**
 * PS_ES_4 - PS_ES_80
 * Incoke a functional component EditScheduleComponent with props to open and close popup
 * @param props
 * @returns
 */
export function EditScheduleComponent(props: EditScheduleProps) {
  /**
   * PS_ES_5 - PS_ES_13
   * Declaring state variables and destructing context
   */
  const classes = takePaymentStyles();
  const payclasses = PayHistory();
  const { customerId } = useParams<ParamType>();
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const {
    paymentSchedule,
    agreementDetails,
    setAgreementDetails,
    customerInfo,
    setCustomerInfo,
    setRenderContext,
    renderContext,
  } = useContext(agreementContext);
  const [dropDownList, setDropDownList] = useState<DropDownArray[]>([]);
  const [rangeDropDownList, setRangeDropDownList] = useState<DropDownArray[]>(
    []
  );
  const [selectedValue, setSelectedValue] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [selectedRangeValue, setSelectedRangeValue] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [currentGridData, setCurrentGridData] = useState<GridData[]>([]);
  const [newGridData, setNewGridData] = useState<GridData[]>([]);
  const [rangeEnable, setRangeEnable] = useState<boolean>(false);
  const [saveLoader, setSaveLoder] = useState<boolean>(false);
  const selectedAgreement = agreementDetails.filter(
    (eachAgreement: AgreementDetails) => eachAgreement.selected
  );
  /**
   * PS_ES_14 - PS_ES_17
   * Within useEffect, it calls loadData function and scheduleAmountCalculation
   * And sets the schedule to selectedValue
   */
  useEffect(() => {
    if (selectedAgreement[0].agreementSchedule.includes(CONSTANTS.SEMI)) {
      setSelectedValue(CONSTANTS.SEMI);
      setSelectedRangeValue(selectedAgreement[0].agreementSchedule);
      setRangeEnable(true);
    } else setSelectedValue(selectedAgreement[0].agreementSchedule);
    scheduleDueAmountCalculation(selectedAgreement[0].agreementSchedule, true);
    loadData();
  }, []);
  /**
   * PS_ES_18 - PS_ES_22
   * The addSuffix function adds suffix for the passed numbers
   * @param num
   * @returns
   */
  const addSuffix = (num: number) => {
    if (num < 1000) {
      if (num % 10 === 1 && num % 100 != 11) {
        return num + CONSTANTS.ST;
      } else if (num % 10 === 2 && num % 100 != 12) {
        return num + CONSTANTS.ND;
      } else if (num % 10 === 3 && num % 100 != 13) {
        return num + CONSTANTS.RD;
      } else {
        return num + CONSTANTS.TH;
      }
    }
  };
  /**
   * PS_ES_23 - PS_ES_29
   * This function loadData, retrives payment schedule form the context
   * and sets it to the dropdown
   */
  const loadData = () => {
    const paymentScheduleReference = paymentSchedule?.filter(
      (item: PaymentScheduleReferences) =>
        item.referenceKey === CONSTANTS.PAYMENT_SCHEDULE
    )[0].referenceDetails;
    // let scheduleArray = [...new Set(paymentSchedule.map(item => item.description))].map(schedule => ({ label: schedule, value: schedule }));
    const scheduleArray = [
      ...new Map(
        paymentScheduleReference.map((item) => [
          item.description,
          {
            label: item.description,
            value: item.referenceCode.includes(CONSTANTS.SEMI)
              ? CONSTANTS.SEMI
              : item.referenceCode,
          },
        ])
      ).values(),
    ];
    setDropDownList(scheduleArray);
    const semiMonthlySchedule = paymentScheduleReference.filter(
      (item) => item.description === CONSTANTS.SEMI_MONTHLY
    );
    // const sortedRangeValue = semiMonthlySchedule.sort((a, b) =>
    //   a.displaySeq.localeCompare(b.displaySeq)
    // );

    const rangeVal = semiMonthlySchedule.map((ele) => {
      const value = ele.referenceCode.replace(/SEMI/g, CONSTANTS.EMPTY_STRING);
      const tempArr = value.split(CONSTANTS.HYPHEN);

      const finalRange =
        addSuffix(Number(tempArr[0])) +
        CONSTANTS.STRING_WITH_SINGLE_SPACE +
        addSuffix(Number(tempArr[1]));
      return { label: finalRange, value: ele.referenceCode };
    });

    const sortedRange = rangeVal.sort((a, b) => {
      const [aFirst, aSecond] = a.label.split(' ');
      const [bFirst, bSecond] = b.label.split(' ');

      const aFirstNum = parseInt(aFirst);
      const bFirstNum = parseInt(bFirst);
      const aSecondNum = parseInt(aSecond);
      const bSecondNum = parseInt(bSecond);

      if (aFirstNum !== bFirstNum) {
        return aFirstNum - bFirstNum;
      }
      return aSecondNum - bSecondNum;
    });

    setRangeDropDownList([
      { label: CONSTANTS.SELECT, value: CONSTANTS.SELECT },
      ...sortedRange,
    ]);
  };
  /**
   * PS_ES_30 - PS_ES_32
   * This function binds the header for the edit schedule popup
   * @returns
   */
  const editScheduleHeadr = () => {
    const headerArrray = CONSTANTS.EDIT_SCHEDULE_POPUP_HEADER;
    return (
      <>
        {headerArrray.map((el: string, index: number) => (
          <RACTableCell key={index}>{el}</RACTableCell>
        ))}
      </>
    );
  };
  /**
   * PS_ES_33 - PS_ES_37
   * This function binds the body to the UI
   * based on the type passed as argument
   * @param type
   * @returns
   */
  const editScheduleContent = (type: string) => {
    let gridData: GridData[] = [];
    if (type == CONSTANTS.CURRENT) gridData = currentGridData;
    if (type == CONSTANTS.NEW) gridData = newGridData;
    return (
      <>
        {gridData.map((value: GridData) => {
          return (
            <RACTableRow
              key={'dynamicgridrow'}
              data-testid={'dynamicgridrow'}
              className={classes.hiddenRow}
            >
              <RACTableCell>{value.agreementNumber}</RACTableCell>

              <RACTableCell>{schedule(value.Schedule)}</RACTableCell>

              <RACTableCell>
                {!CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
                  value.nextDueDate
                ) &&
                !CONSTANTS.NOTE_AGR_NEXT_DUE_DATE_ARRAY.includes(
                  value.nextDueDate
                )
                  ? dateFormat(value.nextDueDate, CONSTANTS.MM_DD_YYYY)
                  : dueDateFormat(value.nextDueDate)}
              </RACTableCell>

              <RACTableCell>
                {CONSTANTS.DOLLAR +
                  Number(value.actualAgreementRate).toFixed(2)}
              </RACTableCell>

              <RACTableCell className={classes.textcenter}>
                {CONSTANTS.DOLLAR + Number(value.actualLdwRate).toFixed(2)}
              </RACTableCell>

              <RACTableCell>
                {CONSTANTS.DOLLAR + Number(value.actualPolicyRate).toFixed(2)}
              </RACTableCell>

              <RACTableCell>
                {CONSTANTS.DOLLAR + Number(value.actualTotalTax).toFixed(2)}
              </RACTableCell>

              <RACTableCell>
                {CONSTANTS.DOLLAR + Number(value.actualTotalAmount).toFixed(2)}
              </RACTableCell>
            </RACTableRow>
          );
        })}
      </>
    );
  };
  /**
   * PS_ES_38 - PS_ES_44
   * This function triggers in the onChange of the schedule drop down
   * It sets valuein the state variable and enables range drop down if semi schedule is selected
   * @param e
   */
  const editScheduleDropDownOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const val = e.target.value;
    setSelectedValue(val);
    if (val == CONSTANTS.SEMI) {
      setRangeEnable(true);
      setSelectedRangeValue(rangeDropDownList[1].value);
      scheduleDueAmountCalculation(rangeDropDownList[1].value);
    } else {
      setRangeEnable(false);
      setSelectedRangeValue(CONSTANTS.EMPTY_STRING);
      scheduleDueAmountCalculation(val);
    }
  };
  /**
   * PS_ES_45 - PS_ES_48
   * This triggers in the onChange of range drop down
   * It sets the range value and calls scheduleDueAmountCalculation()
   * @param e
   */
  const handleRangeDropDown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const range = e.target.value;
    setSelectedRangeValue(range);
    scheduleDueAmountCalculation(range);
  };
  /**
   * PS_ES_49 - PS_ES_56
   * This function calculates the agreement details with respect to the schedule changed
   * @param schedule
   * @param currentGridOnLoad
   */
  const scheduleDueAmountCalculation = async (
    schedule: string,
    currentGridOnLoad?: boolean
  ) => {
    const AdjustDueReq = await adjustDueDateCalciPayloadFormation(
      selectedAgreement
    );

    const editScheduleRequest: AdjustDueDateReq = {
      rateResponse: AdjustDueReq,
      schedule: schedule,
    };

    const adjustDueRes = await AdjustDueDatecalci(editScheduleRequest);
    const gridData: GridData[] = [];

    adjustDueRes.forEach((el: AdjustDueResponse) => {
      const resGridData = {
        agreementNumber:
          el.actualEditedValue?.agreementNumber ?? CONSTANTS.EMPTY_STRING,
        Schedule: el.actualEditedValue?.schedule ?? CONSTANTS.EMPTY_STRING,
        actualAgreementRate:
          el.actualEditedValue?.actualAgreementRate ?? CONSTANTS.EMPTY_STRING,
        actualLdwRate:
          el.actualEditedValue?.actualLdwRate ?? CONSTANTS.EMPTY_STRING,
        actualPolicyRate:
          el.actualEditedValue?.actualPolicyRate ?? CONSTANTS.EMPTY_STRING,
        actualTotalAmount:
          el.actualEditedValue?.actualTotalAmount ?? CONSTANTS.EMPTY_STRING,
        actualTotalTax:
          el.actualEditedValue?.actualTotalTax ?? CONSTANTS.EMPTY_STRING,
        nextDueDate: el.nextDueDate,
        currentDueDate: el.currentDueDate,
      };
      gridData.push(resGridData);
    });
    setNewGridData(gridData);
    currentGridOnLoad ? setCurrentGridData(gridData) : null;
  };
  /**
   * PS_ES_57 - PS_ES_68
   * This function triggers in the onClick
   * It set the changed data to the context
   */
  const editScheduleSaveOnclick = async () => {
    setSaveLoder(true);

    const commonModuleResponse = await commonModuleCall();

    const finalResponse: AgreementDetails[] = [];
    agreementDetails.forEach((el: AgreementDetails) => {
      if (el.selected) {
        let commonModuleAgreement: (AgreementPaymentDetails &
          ClubPaymentDetails)[] = [];
        if (el.agreementType != CONSTANTS.CLUB) {
          commonModuleAgreement =
            commonModuleResponse.agreementPaymentDetails.filter(
              (item: AgreementPaymentDetails) =>
                item.agreementId == el.agreementId
            );
        } else {
          commonModuleAgreement =
            commonModuleResponse.clubPaymentDetails.filter(
              (item: ClubPaymentDetails) =>
                item.clubMembershipNumber == el.agreementNumber
            );
        }
        updateSelectedAgreement(commonModuleAgreement, finalResponse, el);
      } else finalResponse.push({ ...el });
    });
    setAgreementDetails(finalResponse);
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
    setSaveLoder(false);
    setRenderContext(!renderContext);
    props.isClosed(false);
  };

  const commonModuleCall = async () => {
    const adjustmentData = {
      schedule:
        selectedValue == CONSTANTS.SEMI ? selectedRangeValue : selectedValue,
    };
    const payload = await commonModulePayloadFormation(
      selectedAgreement,
      adjustmentData,
      [CONSTANTS.ES]
    );

    const commonPayload = {
      customerId: customerId,
      storeNumber: storeNumber,
      checkedAgr: payload,
    };
    const commonModulePayload = await calculationModulePayloadFormation(
      commonPayload
    );
    const commonModuleServiceRes = await calculatePaymentModule(
      commonModulePayload
    );

    return commonModuleServiceRes.data.paymentDetails[0];
  };

  const updateSelectedAgreement = (
    commonModuleAgreement: (AgreementPaymentDetails & ClubPaymentDetails)[],
    finalResponse: AgreementDetails[],
    el: AgreementDetails
  ) => {
    const totalAmountWithTax =
      Number(commonModuleAgreement[0].totalAmount) +
      Number(commonModuleAgreement[0].totalTax);
    finalResponse.push({
      ...el,
      agreementType: commonModuleAgreement[0].agreementType ?? el.agreementType,
      nextDueDate: commonModuleAgreement[0].nextDueDate
        ? dueDateFormat(commonModuleAgreement[0].nextDueDate)
        : commonModuleAgreement[0].isFinalPayment
        ? dueDateFormat(commonModuleAgreement[0].isFinalPayment)
        : dueDateFormat(el.nextDueDate),
      totalAmount: commonModuleAgreement[0].totalAmount,
      totalDueAmount: totalAmountWithTax.toFixed(2),
      agreementSchedule:
        commonModuleAgreement[0].editedSchedule ?? el.agreementSchedule,
      isEditScheduleEdited: true,
      totalTax: commonModuleAgreement[0].totalTax,
      editSchedule: commonModuleAgreement[0].editedSchedule,
    });
  };
  /**
   * PS_ES_69 - PS_ES_78
   * This function holds the html for the edit schedule popup
   * @returns
   */
  const editSchedule = () => {
    return (
      <>
        <Grid>
          <Grid>
            <Grid
              className={payclasses.payHistm2}
              data-testid="editScheduleCard"
            >
              <Grid
                item
                className={` ${classes.w50}`}
                data-testid="newScheduleDropDown"
              >
                <label
                  htmlFor="Edit-Schedule"
                  className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg2} ${classes.marginLeftEditSchedule}`}
                >
                  New Schedule
                </label>
                <div className={`${classes.raccollg3} ${classes.floatLeft}`}>
                  <RACSelect
                    data-testid="EditScheduleId"
                    // loading={loadSchedule}
                    options={dropDownList}
                    defaultValue={selectedValue}
                    onChange={(e) => editScheduleDropDownOnChange(e)}
                  ></RACSelect>
                </div>
              </Grid>
              {rangeEnable ? (
                <Grid
                  item
                  className={` ${classes.w50}`}
                  data-testid="rangeDropDown"
                >
                  <label
                    htmlFor="Edit-Schedule"
                    className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg2} ${classes.marginLeftEditSchedule}`}
                    style={{ width: '50px' }}
                  >
                    Range
                  </label>
                  <div className={`${classes.raccollg3} ${classes.floatLeft}`}>
                    <RACSelect
                      data-testid="EditScheduleId"
                      options={rangeDropDownList}
                      defaultValue={selectedRangeValue}
                      isDisabled={!rangeEnable}
                      onChange={(e) => handleRangeDropDown(e)}
                    ></RACSelect>
                  </div>
                </Grid>
              ) : null}

              <Grid container spacing={4} className={payclasses.payHistmb3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className={payclasses.payHistmb4}
                >
                  <Grid className={payclasses.payHistm2}>
                    <Typography variant="h5">Current</Typography>
                  </Grid>
                  <Grid className={`${payclasses.payHistm2} `}>
                    <RACTable
                      renderTableHead={() => editScheduleHeadr()}
                      renderTableContent={() =>
                        editScheduleContent(CONSTANTS.CURRENT)
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className={payclasses.payHistmb4}
                >
                  <Grid className={payclasses.payHistm2}>
                    <Typography variant="h5">New</Typography>
                  </Grid>
                  <Grid className={`${payclasses.payHistm2} `}>
                    <RACTable
                      renderTableHead={() => editScheduleHeadr()}
                      renderTableContent={() =>
                        editScheduleContent(CONSTANTS.NEW)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
              >
                <RACButton
                  className={classes.ms2}
                  color="primary"
                  variant="outlined"
                  name="clearCancel"
                  data-bs-toggle="modal"
                  style={{
                    backgroundColor: 'white',
                    color: '#2C3333',
                  }}
                  onClick={() => {
                    props.isClosed(false);
                  }}
                >
                  Cancel
                </RACButton>
                <RACButton
                  data-testid="saveBtn"
                  className={classes.ms2}
                  color="primary"
                  variant="contained"
                  name="De_saveEditScheduleBtn"
                  data-bs-toggle="modal"
                  disabled={saveLoader}
                  loading={saveLoader}
                  onClick={() => editScheduleSaveOnclick()}
                >
                  Save
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  /**
   * PS_ES_79 - PS_ES_78
   * within the return part, It calls editSchedule function within a modal card
   */
  return (
    <>
      <RACModalCard
        isOpen={props.editSchedulePoppup}
        maxWidth="xl"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => props.isClosed(false)}
        title="Edit Schedule"
      >
        {editSchedule()}
      </RACModalCard>
    </>
  );
}
