/*eslint-disable*/
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { RACButton, Grid } from '@rentacenter/racstrap';
import { useHistory } from 'react-router';
import { OtherActionComponent } from './otherAction/OtherAction';
import { OtherIncomeComponent } from './otherIncome/OtherIncome';
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { ToggleClub } from './ToggleClub';
import { TextPayPopup } from './TextPayPopup';
import { AcceptPayment } from './AcceptPayment';
import { getNonInvSalesOptions } from '../../api/user';
import { NonInventorySaleDropDownResponse } from '../interface/nonInventorySaleInterface';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { DeliveryContext } from '../../context/DeliveryContext';
import { AppRoute } from '../../config/route-config';
import CONSTANTS from '../constants/constant';
import { LocationParams, ParamType } from '../interface/commonInterface';
import { continueAMClicked } from './CommitmentContinueOnClick';

export default function PaymentFooter() {
  /**
   * PF_PS_NO_4
   * Declaration of the state variables and destructuring of context is performed.
   */
  const classes = takePaymentStyles();
  const history = useHistory();
  const {
    nonInventorySaleInfo,
    setNonInventorySaleInfo,
    customerOrderPayment,
    multiStoreSelected,
    agreementDetails,
    customerInfo
  } = useContext(agreementContext);
  const { paymentSearchValue } = useContext(DeliveryContext);
  const [searchVal, setsearchVal] = paymentSearchValue;
  const [redirectToAccountManagement, setRedirectToAccountManagement] = useState(false);
  const location = useLocation<LocationParams>();
  const urlParams = new URLSearchParams(window.location.search);
  const isCommitment = urlParams.get('origin');

  /**
   * PF_PS_NO_5 - PF_PS_NO_8
   * This useEffect is used to fetch the Non-Inventory sale options and
   * update it to context.
   */
  useEffect(() => {
    if (!nonInventorySaleInfo.typeDropDownResp.length) {
      (async () => {
        const typeDropDownResp: NonInventorySaleDropDownResponse =
          await getNonInvSalesOptions({
            salesType: CONSTANTS.EMPTY_STRING,
            provider: CONSTANTS.EMPTY_STRING,
          });
        //   setNonInventorySaleInfo({...nonInventorySaleInfo,typeDropDownResp:typeDropDownResp.data})
        if (typeDropDownResp.status == 200) {
          setNonInventorySaleInfo({
            ...nonInventorySaleInfo,
            typeDropDownResp: typeDropDownResp.data,
          });
        }
      })();
    }
  }, []);
  /**
   * @returns
   * PF_PS_NO_9
   * The cancleOnclick() method is invoked when the user clicks the cancle button
   */
  const cancelOnclick = () => {
    console.log('location', location)
    if (location?.state?.redirectSource === 'am') {
      setRedirectToAccountManagement(true);
    }
    else redirectionTopaymentsearch()
  };

  const redirectionTopaymentsearch = () => {
    const request = searchVal;
    request.cancelClick = false;
    setsearchVal(request);
    history.push({
      pathname: `/payment1/paymentsearch`,
    });
  };


  if (redirectToAccountManagement) {
    return (
      <Redirect
        to={{
          pathname: AppRoute.AccountManagement.replace(
            ':customerId',
            location?.state?.accountManagementCustomerId ||
            CONSTANTS.EMPTY_STRING
          ),
          state: {
            ...(location?.state || {}),
            redirectSource: 'payment',
          },
        }}
      />
    );
  }

  return (
    <Grid>
      <Grid item className={classes.floatLeft}>
        <RACButton
          type="button"
          color="primary"
          variant="outlined"
          data-testid="Cancel_id"
          onClick={() => cancelOnclick()}
        >
          Cancel
        </RACButton>
      </Grid>
      <Grid item className={`${classes.floatRight} ${classes.displayFlex}`}>
        {/**
         * PF_PS_NO_10 - PF_PS_NO_13
         * This component helps to render the other action button
         */}
        {customerOrderPayment == CONSTANTS.EMPTY_STRING ? (
          <>
            <Grid
              item
              className={`${classes.btngroup} ${classes.floatLeft}`}
              role="group"
            >
              <OtherActionComponent />
            </Grid>
            <Grid
              item
              className={`${classes.btngroup} ${classes.floatLeft} ${classes.displayFlex}`}
              role="group"
            >
              {/**
               * PF_PS_NO_14 - PF_PS_NO_17'
               * This component helps to render the other income button.
               */}
              {(isCommitment == undefined) ?
                <OtherIncomeComponent /> : null}


              {/**PF_PS_NO_18
               * This club helps to render the Activate/Deactivate club button.
               */}
              <ToggleClub />
            </Grid>
          </>
        ) : null}
        {/**
         * PF_PS_NO_19
         * This component helps to render the Txt2Pay button
         */}
        {customerOrderPayment == CONSTANTS.EMPTY_STRING && (isCommitment == undefined) ? (
          <TextPayPopup />
        ) : null}


        {/**
         * PF_PS_NO_20
         * This component helps to render the Accept Payment button.
         */}
        {(isCommitment == undefined) ? <AcceptPayment /> : <RACButton
          color="primary"
          variant="contained"
          className={classes.ms2}
          disabled={multiStoreSelected}
          onClick={() => continueAMClicked(agreementDetails, location, customerInfo, history)}
        >
          {" "}
          Continue
        </RACButton>}
      </Grid>
    </Grid>
  );
}


