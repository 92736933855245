/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useContext, useEffect, useState } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { Grid, RACAlert } from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import CONSTANTS from '../constants/constant';
import { dateFormat } from '../utils/scheduleAndDateFormat';
import { AgreementDetails } from '../interface/paymentInfoInterface';

/**
 * PS_PE_02
 * Declare a function as PaymentException()
 * @returns
 */
export function PaymentException() {
  /**
   * PS_PE_03 - PS_PE_06
   * Declaring state variables and destructing from the context
   */
  const classes = takePaymentStyles();
  const [exceptionText, setExceptionText] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [brokenCommitmentText, setBrokenCommitmentText] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [simsCommitmentText, setSIMSCommitmentText] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );

  const {
    customerInfo,
    agreementDetails,
    setPaymentDisable,
    customerOrderPayment,
  } = useContext(agreementContext);
  const pendingDelivery = agreementDetails.filter(
    (el: AgreementDetails) =>
      ((!el.deliveryFlag && Number(el.numberOfPaymentsMade) >= 3) ||
        el.switchoutDeliveryStatus == CONSTANTS.STATUS_YES) &&
      el.selected
  );
  /**
   * PS_PE_06
   * Declare coInitialDiffStore variable and check if it is initial flow
   * And different store agreements selected
   */
  const coInitialDiffStore =
    customerOrderPayment == CONSTANTS.IS_CO_INITIAL &&
    agreementDetails[0].storeNumber &&
    agreementDetails[0].storeNumber !=
      sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      ? true
      : false;

  /**
   * PS_PE_07 & PS_PE_08
   * Within useEffect call handleExceptionData() and handleCommitmentException()
   */
  useEffect(() => {
    handleExceptionData();
    handleCommitmentException();
  }, [agreementDetails, customerInfo]);

  /**
   * PS_PE_09 - PS_PE_25
   * This function checks for customer verified status,
   * pending delivery and co initial with different store agreement selected
   */
  const handleExceptionData = () => {
    const customerVerified = customerInfo.customerVerifiedStatus;
    if (coInitialDiffStore) {
      setExceptionText(CONSTANTS.DIFF_STORE_CO_INITIAL_EXCEPTION_TEXT);
    } else if (pendingDelivery.length > 0) {
      setPaymentDisable((prev) => ({ ...prev, alertDisable: true }));
      const agreementIdArray: string[] = [];
      pendingDelivery.forEach((eachDelivery: AgreementDetails) =>
        eachDelivery.switchoutDeliveryStatus === CONSTANTS.STATUS_YES
          ? agreementIdArray.push(eachDelivery.agreementNumber + '(Switchout)')
          : agreementIdArray.push(eachDelivery.agreementNumber)
      );
      setExceptionText(
        `Selected Agreement# ${agreementIdArray} delivery is not confirmed. Please confirm delivery to continue with payment`
      );
    } else if (customerVerified === CONSTANTS.PENDING_CAPS)
      setExceptionText(CONSTANTS.CUSTOMER_VERIFICATION_EXCEPTION_TEXT);
    else setExceptionText(CONSTANTS.EMPTY_STRING);

    if (pendingDelivery.length == 0)
      setPaymentDisable((prev) => ({ ...prev, alertDisable: false }));
  };

  /**
   * PS_PE_10 - PS_PE_14
   * This function handles commitment data
   * It checks for broken commitment or commitment taken in SIMS.
   */
  const handleCommitmentException = () => {
    if (
      customerInfo?.customerPaymentInfo?.commitment?.length >
      CONSTANTS.ZERO_NUMBER
    ) {
      const commitmentDetails =
        customerInfo?.customerPaymentInfo?.commitment[0];
      if (
        commitmentDetails.commitmentStatusCode == CONSTANTS.BR &&
        commitmentDetails.createdSource == CONSTANTS.RACDB
      ) {
        let formattedLastPaymentDate;
        const commitmentDate = commitmentDetails.commitmentDate;
        const lastPaymentDate = commitmentDetails.lastPaymentDate;
        const commitmentDateTimeParts = commitmentDate?.split(
          CONSTANTS.SPLIT_WITH_T
        );

        const formattedCommitmentDate = dateFormat(
          commitmentDateTimeParts[0],
          CONSTANTS.MM_DD_YYYY
        );
        if (lastPaymentDate) {
          const lastPaymentDateTimeParts = lastPaymentDate?.split(
            CONSTANTS.SPLIT_WITH_T
          );
          formattedLastPaymentDate = dateFormat(
            lastPaymentDateTimeParts[0],
            CONSTANTS.MM_DD_YYYY
          );
        }

        const twelveHourTime = new Date(commitmentDate).toLocaleTimeString(
          'en-US',
          { hour: 'numeric', minute: 'numeric', hour12: true }
        );
        if (
          formattedLastPaymentDate < formattedCommitmentDate ||
          (formattedCommitmentDate && !formattedLastPaymentDate)
        )
          setBrokenCommitmentText(
            `Broken commitment - ${formattedCommitmentDate} ${twelveHourTime}`
          );
      }
      if (
        commitmentDetails.commitmentStatusCode == CONSTANTS.OP &&
        commitmentDetails.createdSource == CONSTANTS.ENTERPRISE
      ) {
        setPaymentDisable((prev) => ({ ...prev, alertDisable: true }));
        setSIMSCommitmentText(CONSTANTS.SIMS_COMMITMENT_EXCEPTION_TEXT);
      }
    }
  };

  /**
   * PS_PE_26 & PS_PE_27
   * Within return, it binds the alert to UI
   * broken commitment alert can be closed
   */
  return (
    <>
      <Grid>
        {exceptionText ? (
          <RACAlert
            AlertMessage={exceptionText}
            variant="outlined"
            severity={
              pendingDelivery.length > 0 || coInitialDiffStore
                ? 'error'
                : 'warning'
            }
          />
        ) : null}

        {simsCommitmentText ? (
          <RACAlert
            AlertMessage={simsCommitmentText}
            variant="outlined"
            severity="error"
          />
        ) : null}

        {brokenCommitmentText ? (
          <RACAlert
            className={`${classes.cmtButtonStyle}`}
            AlertMessage={brokenCommitmentText}
            variant="outlined"
            severity="error"
            handleClose={() => setBrokenCommitmentText(CONSTANTS.EMPTY_STRING)}
          />
        ) : null}
      </Grid>
    </>
  );
}
