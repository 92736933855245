/* eslint-disable*/
import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  RACButton,
  RACSelect,
  ListItem,
  List,
  CircularProgress
} from '@rentacenter/racstrap';
import Modal from 'react-modal';
import clsx from 'clsx';
import { GlobalStyles } from '../../../stylesJS/global';
import { nonInventorySaleStyles } from '../../../stylesJS/nonInventorySaleStyles';
import { useHistory, useParams } from 'react-router-dom';
import { agreementContext } from '../../context/PaymentInformationContext';
import { getNonInvSalesOptions } from '../../../api/user';
import { cashSaleTypeFormation, otherIncomeResponseFormation, typeDropDown, xboxDropDownLoad } from '../../../utils/utils';
import { AcimaOther } from './AcimaOther';
import { XboxMemberShipGiftCard } from './XboxMemberShipGiftCard';
import CONSTANTS from '../../constants/constant';
import { AcimaOtherDataInterface, NonInventorySaleDropDownResponse, XboxDataObj } from '../../interface/nonInventorySaleInterface';
import { AgreementDetails } from '../../interface/paymentInfoInterface';
import { ParamType } from '../../interface/commonInterface';
import { AnyCnameRecord } from 'dns';

export function NonInventorySale() {
  const history = useHistory();
  const { customerId, agreementId } = useParams<ParamType>();
  const classes = nonInventorySaleStyles();
  const { nonInventorySaleOptions, setNonInventorySaleOptions, nonInventorySaleInfo, setNonInventorySaleInfo, setAgreementDetails, featureFlags, agreementDetails, customerInfo } = useContext(agreementContext);
  const [nonInventorySaleType, setNonInventorySaleType] = useState<string>(CONSTANTS.SELECT);
  const [blurLoaderActive, setblurLoaderActive] = useState<boolean>(false);
  const acimaOtherDataObj = {
    nonInvSaleType: CONSTANTS.SELECT,
    description: CONSTANTS.EMPTY_STRING,
    salesPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    deliveryFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    deliveryFeeTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    totalSales: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
  }
  const xboxDataObj = { nonInvSaleType: CONSTANTS.SELECT, choosenProvider: CONSTANTS.SELECT, choosenRatePlan: CONSTANTS.SELECT, choosenRateLabel: CONSTANTS.EMPTY_STRING, choosenProviderLabel: CONSTANTS.EMPTY_STRING, salesPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING, salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING, totalSales: CONSTANTS.ZREO_TWO_DECIMAL_STRING, disableRatePlan: true }
  const [acimaOtherData, setacimaOtherData] = useState<AcimaOtherDataInterface>(acimaOtherDataObj);
  const [xboxData, setXboxData] = useState<XboxDataObj>(xboxDataObj);
  const [loadType, setloadType] = useState<boolean>(false);
  const [submitBtndisable, setSubmitBtnDisable] = useState<boolean>(false);

  useEffect(() => {
    
    if (!nonInventorySaleInfo.typeDropDownResp.length) {
      (async () => {
        setloadType(true);
        const typeDropDownResp: NonInventorySaleDropDownResponse = await getNonInvSalesOptions({ salesType: CONSTANTS.EMPTY_STRING, provider: CONSTANTS.EMPTY_STRING });
        if (typeDropDownResp.status == 200) {
          setloadType(false);
          const formAvailableSalesType = typeDropDown(featureFlags, typeDropDownResp.data);
          setNonInventorySaleOptions({ ...nonInventorySaleOptions, typeDropDownArray: formAvailableSalesType });
        }
      })()
    }
    else {
      const formAvailableSalesType = typeDropDown(featureFlags, nonInventorySaleInfo.typeDropDownResp);
      setNonInventorySaleOptions({ ...nonInventorySaleOptions, typeDropDownArray: formAvailableSalesType });
    }
    
    if(nonInventorySaleInfo.editIndex>CONSTANTS.ONE_NEGATIVE_NUMBER.toString()){
      
      const index = Number(nonInventorySaleInfo.editIndex);
      if(agreementDetails[index].agreementType == CONSTANTS.AGREEMENTTYPEMAPPER[CONSTANTS.ACIMA_RECOVERY_TYPE] || agreementDetails[index].agreementType == CONSTANTS.AGREEMENTTYPEMAPPER[CONSTANTS.OTHER]){
        setNonInventorySaleType(String(agreementDetails[index].miscellaneousItemInfo?.nonInvSaleType) || '');
        setacimaOtherData(agreementDetails[index].miscellaneousItemInfo as AcimaOtherDataInterface);
      }
      else{
        setNonInventorySaleType(String(agreementDetails[index].miscellaneousItemInfo?.nonInvSaleType) || '');
        setXboxData(agreementDetails[index].miscellaneousItemInfo as XboxDataObj);
      }
    }
  }, [])

  useEffect(() => {
    if (nonInventorySaleType == CONSTANTS.ACIMA_RECOVERY_TYPE || nonInventorySaleType == CONSTANTS.OTHER) {
      if (acimaOtherData.salesPrice == CONSTANTS.ZREO_TWO_DECIMAL_STRING || acimaOtherData.description == CONSTANTS.EMPTY_STRING) {
        setSubmitBtnDisable(true);
      }
      else {
        setSubmitBtnDisable(false);
      }
    }
    else if (nonInventorySaleType == CONSTANTS.XBOX_GIFT_CARD_TYPE || nonInventorySaleType == CONSTANTS.XBOX_GIFT_MEMBERSHIP_TYPE) {
      if (xboxData.choosenProvider == CONSTANTS.SELECT || xboxData.salesPrice == CONSTANTS.ZREO_TWO_DECIMAL_STRING) {
        setSubmitBtnDisable(true);
      }
      else {
        setSubmitBtnDisable(false);
      }
    }
  }, [xboxData, acimaOtherData])


  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>) => {
    setNonInventorySaleType(event.target.value);
    setacimaOtherData({ ...acimaOtherDataObj, nonInvSaleType: event.target.value });
    setXboxData({ ...xboxDataObj, nonInvSaleType: event.target.value });
    setNonInventorySaleOptions({ ...nonInventorySaleOptions, xBoxProviderValues: [] });
  }

  const submitOnclick = async () => {
    
    let otherIncomeAgr: AgreementDetails[];
    setblurLoaderActive(true);
    if (nonInventorySaleType == CONSTANTS.ACIMA_RECOVERY_TYPE || nonInventorySaleType == CONSTANTS.OTHER) {
      otherIncomeAgr = await otherIncomeResponseFormation([
        {
          agreementNumber: CONSTANTS.ADJUST,
          agreementId: CONSTANTS.ADJUST,
          salesTax: acimaOtherData.salesTax,
          amount: acimaOtherData.totalSales,
          type: CONSTANTS.AGREEMENTTYPEMAPPER[nonInventorySaleType],
          deliveryAmount: acimaOtherData.deliveryFee,
          deliveryAmountTax: acimaOtherData.deliveryFeeTax,
          miscellaneousItemInfo:acimaOtherData,
          counter:agreementDetails.length +1,
          description: nonInventorySaleType == CONSTANTS.ACIMA_RECOVERY_TYPE ? 'Acima Recovery Settlement - ' + acimaOtherData.description : acimaOtherData.description
        },
      ]);
      
      updateAgreementDetails(otherIncomeAgr, nonInventorySaleType == CONSTANTS.ACIMA_RECOVERY_TYPE ? 'Acima Recovery Settlement - ' + acimaOtherData.description : acimaOtherData.description);
    }
    else {
      otherIncomeAgr = await otherIncomeResponseFormation([
        {
          agreementNumber: CONSTANTS.ADJUST,
          agreementId: CONSTANTS.ADJUST,
          salesTax: xboxData.salesTax,
          amount: xboxData.totalSales,
          type: CONSTANTS.AGREEMENTTYPEMAPPER[nonInventorySaleType],
          miscellaneousItemInfo:xboxData,
          counter:agreementDetails.length +1,
          description: xboxData.choosenProviderLabel + CONSTANTS.STRING_WITH_SINGLE_SPACE + xboxData.choosenRateLabel
        },
      ]);
      updateAgreementDetails(otherIncomeAgr, xboxData.choosenProviderLabel + CONSTANTS.STRING_WITH_SINGLE_SPACE + xboxData.choosenRateLabel);

    }
    setNonInventorySaleInfo({ ...nonInventorySaleInfo, editIndex: CONSTANTS.EMPTY_STRING });
    cancelBtn()
    setblurLoaderActive(false);
  }
  
  const updateAgreementDetails = ( otherIncomeAgr: AgreementDetails[], agreementDescription:string) => {
    const index = Number(nonInventorySaleInfo.editIndex);
    if (nonInventorySaleInfo.editIndex >CONSTANTS.ONE_NEGATIVE_NUMBER.toString()) {
    setAgreementDetails((prevDetails) =>
      prevDetails.map((detail, i) =>
        i === index
          ? {
            ...detail,
            totalDueAmount: otherIncomeAgr[0].totalDueAmount,
            totalAmount: otherIncomeAgr[0].totalAmount,
            totalTax: otherIncomeAgr[0].totalTax,
            miscellaneousItemInfo: otherIncomeAgr[0].miscellaneousItemInfo,
            agreementDesc: agreementDescription,
            agreementType: CONSTANTS.AGREEMENTTYPEMAPPER[nonInventorySaleType]
          }
          : detail
      )
    );
  } else {
    setAgreementDetails([...agreementDetails, ...otherIncomeAgr]);
  }
    
  }

  const cancelBtn = () => {
    setNonInventorySaleInfo({ ...nonInventorySaleInfo, editIndex: CONSTANTS.EMPTY_STRING });
    if(customerId)
      {
        if(agreementId){
          history.push({ pathname: `/payment1/paymentinformation/${customerId}/${agreementId}` })
        }
        else{
          history.push({ pathname: `/payment1/paymentinformation/${customerId}/0` })
        }
      }

  }

  const removeAmountDue = () => {
    if (Number(nonInventorySaleInfo.editIndex)>=0) {
      const index = Number(nonInventorySaleInfo.editIndex);
      const agreements = agreementDetails;
      agreements.splice(index, 1);
      setAgreementDetails(agreements);
    }

    setNonInventorySaleInfo({ ...nonInventorySaleInfo, editIndex: CONSTANTS.EMPTY_STRING });
    cancelBtn();
  }

  const clearBtn = () => {
    setNonInventorySaleType(CONSTANTS.SELECT);
    setXboxData(xboxDataObj);
    setacimaOtherData(acimaOtherDataObj);

  }
  return (<>
  <Grid style={{marginTop:'-8%'}}>
  <Modal
      isOpen={blurLoaderActive}
      className={classes.loaderStyle}
      id='openpopup'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      aria-hidden='true'
    >
      <div>
        <div className={classes.RACLoaderPage}>
          <CircularProgress />
        </div>
      </div>
    </Modal>
    <Grid className={classes.racFixedFooterMargin}></Grid>
    <Grid container className={classes.tittlesBreadCrums}>
      <Grid className={classes.m2}>
        <List className={`${classes.dflex} ${classes.p0} `}>
          <ListItem
            className={`${classes.widthAuto} ${classes.p2} ${classes.fontSemiBold} ${classes.floatStart} ${classes.BreadCrumbInactive}`}
          >
            <Typography
              className={`${classes.pr2} ${classes.textBlack} `}
            >
              Dashboard
            </Typography>
          </ListItem>
          <ListItem
            className={`${classes.widthAuto} ${classes.p2} ${classes.floatStart} ${classes.fontSemiBold} ${classes.BreadCrumbInactive}`}
          >
            <Typography
              className={`${classes.pr2} ${classes.textBlack} `}
            >
              Payment
            </Typography>
          </ListItem>
          <ListItem
            className={`${classes.widthAuto} ${classes.p2} ${classes.floatStart} ${classes.fontSemiBold}`}
          >
            <Typography
              className={`${classes.pr2} ${classes.textBlack}  ${classes.textBlue} `}
            >
              Non-Inventory Sales
            </Typography>
          </ListItem>
        </List>
      </Grid>
    </Grid>
    <Card
      className={`${classes.w100}  ${classes.card}  ${classes.cardHeight} ${classes.marginTop} ${classes.cardborder}`}
    >
      <CardContent>
        <Grid container>
          <Grid item md={3}>
            <Typography
              variant='h6'
              className={`${classes.title} ${classes.titleColor}`}
            >
              Non Inventory Sales
            </Typography>
          </Grid>
          <Grid className={classes.custDetailSection}>
            <Grid item md={4} className={classes.paddingCustname}>
              <Typography variant='h6'>Customer Name</Typography>
              <Typography
                variant='h6'
                className={classes.marginCustName}
                style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   checkPermissionForCusRed(
              //     props?.customerInfo?.data?.GetCustomer?.value
              //       ?.customerId
              //   )
              // }
              >
                {`${customerInfo?.customerDetails[0]?.firstName} ${customerInfo?.customerDetails[0]?.lastName}`}
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.paddingCustname}>
              <Typography variant='h6'>Customer ID</Typography>
              <Typography variant='h6' className={classes.marginCustID}>
                {
                  customerInfo.customerId
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card
      className={`${classes.w100}  ${classes.card}  ${classes.cardHeight1} s`}
    >
      <CardContent>
        <Grid container>
          <Grid
            item
            md={2}
            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} `}
          >
            <RACSelect
              inputLabelClassName={`${classes.marginBottom} `}
              inputLabel='Type'
              //classes={{ nativeSelect: classes.dropdown1 }}
              name='type'
              defaultValue={nonInventorySaleType}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChangeType(event)}
              required
              options={nonInventorySaleOptions.typeDropDownArray}
              loading={loadType}
            />
          </Grid>
        </Grid>
        <Grid container>
          {nonInventorySaleType == CONSTANTS.ACIMA_RECOVERY_TYPE || nonInventorySaleType == CONSTANTS.OTHER ?
            <AcimaOther
              nonInventorySaleType={nonInventorySaleType}
              acimaOtherData={acimaOtherData}
              setacimaOtherData={setacimaOtherData}
              blurLoaderActive={blurLoaderActive}
              setblurLoaderActive={setblurLoaderActive}
            /> : null}
          {nonInventorySaleType == CONSTANTS.XBOX_GIFT_CARD_TYPE || nonInventorySaleType == CONSTANTS.XBOX_GIFT_MEMBERSHIP_TYPE ?
            <XboxMemberShipGiftCard
              nonInventorySaleType={nonInventorySaleType}
              xboxData={xboxData}
              setXboxData={setXboxData}
              blurLoaderActive={blurLoaderActive}
              setblurLoaderActive={setblurLoaderActive}
            /> : null}
        </Grid>
      </CardContent>
    </Card>
    <Grid
      item
      classes={{
        root: clsx(classes.customerToolbar, classes.fixedBottom),
      }}
      style={{ zIndex: 0 }}
    >
      <Grid item className={classes.floatLeft}>
        <RACButton
          variant='outlined'
          color='primary'
          className={`${classes.me1} ${classes.foc}`}
          onClick={() => cancelBtn()}
        >
          Cancel
        </RACButton>
      </Grid>
      <Grid className={classes.floatRight}>
        <RACButton
          data-testid='clear-btn'
          variant='contained'
          color='primary'
          className={classes.me1}
          onClick={() => clearBtn()}
        >
          Clear
        </RACButton>
        <RACButton
          data-testid='Remove-Payment-btn'
          variant='contained'
          color='primary'
          className={classes.me1}
          disabled={
            (nonInventorySaleInfo.editIndex>CONSTANTS.ONE_NEGATIVE_NUMBER.toString())
              ? false
              : true
          }
          onClick={() => removeAmountDue()}
        >
          Remove Payment
        </RACButton>
        <RACButton
          data-testid='submit-btn'
          variant='contained'
          color='primary'
          className={classes.me1}
          disabled={submitBtndisable}
          onClick={() => submitOnclick()}
        >
          Submit
        </RACButton>
      </Grid>
    </Grid>
  </Grid>
    

  </>)

}